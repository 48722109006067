// ** React Imports
import { useState, useEffect } from "react";
import axios from "axios";

// ** MUI Imports
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Swal from "sweetalert2";
import RatingQuestions from "./RatingQuestions";
import FreeTextQuestions from "./FreeTextQuestions";
import SingleChoiceQuestions from "./SingleChoiceQuestions";


const CardNavigationCenter = ({currentSessionId, currentSurveyUserId, surveyId}) => {
    // ** State
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
    const [survey, setSurvey] = useState();
    const [surveyquestion, setSurveyquestion] = useState([]);
    const [currentSessionDetails, setCurrentSessionDetails] = useState([]);
    const [filledAnswers, setFilledAnswers] = useState([]);    
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [textAnswers, setTextAnswers] = useState({});
    const [totalSurveyQuestions, setTotalSurveyQuestions] = useState(0);

    const user_id = currentSurveyUserId ? currentSurveyUserId : user.id;
    
    
    const [surveyRatings, setSurveyRatings] = useState({});
    const [surveyRatingSettingId, setSurveyRatingSettingId] = useState(1);
    const [labels, setLabels] = useState({});

    const getList = async () => {
        await axios.get(`/api/competencies/surveyquestion?sessionId=${currentSessionId}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                setSurvey(result.data[0]);
                let surveyData = result.data[0];
                let totalQuestions = 0;
                surveyData.survey_competencies.forEach(competency => {
                    totalQuestions += competency.survey_competency_questions.length;
                });
                setTotalSurveyQuestions(totalQuestions)
            }
        });
    };

    const getSessionDetils = async () => {
        await axios.get(`/api/session?id=${currentSessionId}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                setCurrentSessionDetails(result.data);
                setSurveyRatingSettingId(result.data.surveyRatingSettingId)
            } else {
                // setIsError(true);
            }
        });
    };

    const getSelfEvaluation = async () => {
        let conditions = `?sessionId=${currentSessionId}&userId=${user_id}`;
        conditions +=  currentSurveyUserId ? `&managerId=${currentSurveyUserId}` : '';
        await axios.get(`/api/userselfevaluation/findSelfEvaluation${conditions}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                setIsSubmitted(result.data[0].status)
            }
        });
    };

    const getSurveyAnswers = async () => {
        await axios.get(`/api/findSurevyAnswer?sessionId=${currentSessionId}&userId=${user_id}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                // console.log("-----",result.data)
                setFilledAnswers(result.data);
            }
        });
    };

    const getSurveyRatingsList = async () => {
        console.log('ddddddddddddddddddddd')
        await axios.get(`/api/surveyratingsettings/singleSurveySetting?id=${surveyRatingSettingId}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                setSurveyRatings(result.data);
                let labels = {}
                result.data.setting_labels.forEach((item, index) => {
                    labels[index + 1] = item.label;
                });
                setLabels(labels)
            } else {
                // setIsError(true);
            }
        });
    };
    useEffect(() => {
        console.log('dsssssssssssssss')
        if(parseInt(currentSessionId) > 0){
            getList()
            getSessionDetils()
            getSelfEvaluation()
            getSurveyAnswers()
            setValue(0)
            getSurveyRatingsList();
        console.log('daaaaaaaaaaaaaaaa')

        }
        else if(parseInt(surveyId) > 0){
        console.log('xaaaaaaaaaaaaaa')
        getSurveyRatingsList();

            axios.get(`/api/competencies/surveyquestion?surveyId=${surveyId}`).then(function (response) {
                let result = response.data;
                if (result.success) {
                    setSurvey(result.data[0]);
                    let surveyData = result.data[0];
                    let totalQuestions = 0;
                    surveyData.survey_competencies.forEach(competency => {
                        totalQuestions += competency.survey_competency_questions.length;
                    });
                    setTotalSurveyQuestions(totalQuestions)
                }
            });
        }
    }, [currentSessionId]);
    

    const handleSave = async (e) => {
        try {
            const response = await axios.post("/api/surveys/answer/save", { surveyanswer: filledAnswers });
            Swal.fire(response.data.message, "", "success")
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (e) => {
        try {
            if(totalSurveyQuestions === filledAnswers.length){
                const response = await axios.post("/api/surveys/answer/submit", { surveyanswer: filledAnswers });
                Swal.fire(response.data.message, "", "success").then((result) => {
                    // setTextAnswers({});
                    // setSurveyquestion([]);
                    setIsSubmitted(1);
                });
            }
            else{
                Swal.fire('Please fill all the questions', '', 'warning');
            }
        } catch (error) {
            Swal.fire(error.message, "", "error")
            console.error(error);
        }
    };


    return (
        <TabContext value={value.toString()}>
             {isSubmitted ? (
            <div><br></br><br></br>
                <h3>Thank you..<br></br>Your survey has been successfully submitted..</h3>
            </div>
        ) : ( 
            
            
            survey ? ( // Check if survey is defined before rendering
            <>
                <TabList centered onChange={handleChange} aria-label="card navigation example">
                    {survey.survey_competencies.map((survey_competency, index) => (
                        survey_competency.survey_competency_questions.length > 0 ?
                        <Tab key={survey_competency.competency.id} value={index.toString()} label={survey_competency.competency.name} />
                        : ""
                    ))}
                </TabList>
                <CardContent>
                    {survey.survey_competencies.map((survey_competency, index) => (
                        <TabPanel value={index.toString()} sx={{ p: 3 }}>
                            {survey_competency.survey_competency_questions.map((survey_competency_question, i) => {
                                const answerKey = `${index}_${survey_competency_question.id}`;
                                // const labels = questions.labels || {};
                                return (
                                    <div key={survey_competency_question.id} className="row justify-content-between">
                                        <div className="col-md-7">
                                            <Typography className="fs-13 fw-500" variant="body" sx={{ marginBottom: 2 }}>
                                                {survey_competency_question.question.name}
                                            </Typography>
                                        </div>
                                        {survey_competency_question.question.question_type.id === 1 ? (
                                            <RatingQuestions
                                                index={index}
                                                answerKey={answerKey}
                                                survey_competency_question={survey_competency_question}
                                                currentSessionDetails={currentSessionDetails}
                                                surveyRatings={surveyRatings}
                                                labels={labels}
                                                filledAnswers={filledAnswers}
                                                setFilledAnswers={setFilledAnswers}
                                                currentSurveyUserId={currentSurveyUserId}
                                            />
                                        ) : survey_competency_question.question.question_type.id === 2 ? (
                                            <FreeTextQuestions
                                                index={index}
                                                answerKey={answerKey}
                                                survey_competency_question={survey_competency_question}
                                                currentSessionDetails={currentSessionDetails}
                                                filledAnswers={filledAnswers}
                                                setTextAnswers={setFilledAnswers}
                                                currentSurveyUserId={currentSurveyUserId}
                                            />                                        
                                        ) : (
                                            <SingleChoiceQuestions
                                                index={index}
                                                answerKey={answerKey}
                                                survey_competency_question={survey_competency_question}
                                                currentSessionDetails={currentSessionDetails}
                                            />
                                            
                                        )}
                                        <hr></hr>
                                    </div>
                                );
                            })}
                        </TabPanel>
                    ))}
                    <div className="text-end mt-5">
                        {
                            surveyId === 0 ?
                                isSubmitted ? (
                                <Button size='small' variant="contained"  disabled>
                                    Submit
                                </Button>
                                ) : ( 
                                <>
                                <Button size='small' variant="contained" onClick={handleSave} className="m-2">
                                    Save
                                </Button>
                                <Button size='small' variant="contained" onClick={handleSubmit} disabled={totalSurveyQuestions === filledAnswers.length ? false : true}>
                                    Submit
                                </Button>
                                </>
                                ) : ''
                        }
                        
                    </div>
                </CardContent>
            </>
            ) : null

        )}
        
        </TabContext>
    );
};

export default CardNavigationCenter;
