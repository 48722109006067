import { Link } from "react-router-dom";
import React from 'react';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';



export const Ourblog = () => {
    //Owl Carousel Settings
    const state = {
        // margin: 30,
        responsiveClass: true,
        nav: true,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    };
    return (
     <section className="py-5">
       <div className="container">
            <div className="row align-items-center mb-4">
                <div className="col-lg-9 mx-auto text-center">
                    <p className="h6 text-theme text-uppercase">Our Blog</p>
                    <h2 className="mb-3">Lorem ipsum dolor sit amet</h2>
                    <p className="text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
            <div className="row mb-4">
                <div className='col-md-12'>
                    <OwlCarousel className='ss_carousel' dots={false} responsive={state.responsive} items={3} autoplay ={true} touchDrag={true} lazyLoad={true}>
                        <div className="item">
                            <div className="card border">
                                <img src="./images/hero-imgg.jpg" class="card-img-top blogcard_img" alt="..." />
                                <div className="card-body">
                                    <h5 class="card-title mb-4 fs-15">Lorem ipsum dolor sit amet</h5>
                                    <p class="card-text text-muted fs-13">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <div className="d-grid">
                                        <Link href="#" class="btn btn-theme fs-14 fw-semibold">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card border">
                                <img src="./images/hero-imgg.jpg" class="card-img-top blogcard_img" alt="..." />
                                <div className="card-body">
                                    <h5 class="card-title mb-4 fs-15">Lorem ipsum dolor sit amet</h5>
                                    <p class="card-text text-muted fs-13">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <div className="d-grid">
                                        <Link href="#" class="btn btn-theme fs-14 fw-semibold">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card border">
                                <img src="./images/hero-imgg.jpg" class="card-img-top blogcard_img" alt="..." />
                                <div className="card-body">
                                    <h5 class="card-title mb-4 fs-15">Lorem ipsum dolor sit amet</h5>
                                    <p class="card-text text-muted fs-13">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <div className="d-grid">
                                        <Link href="#" class="btn btn-theme fs-14 fw-semibold">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card border">
                                <img src="./images/hero-imgg.jpg" class="card-img-top blogcard_img" alt="..." />
                                <div className="card-body">
                                    <h5 class="card-title mb-4 fs-15">Lorem ipsum dolor sit amet</h5>
                                    <p class="card-text text-muted fs-13">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <div className="d-grid">
                                        <Link href="#" class="btn btn-theme fs-14 fw-semibold">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 mx-auto">
                    <Link href="#" className="btn btn-theme fw-semibold py-3 w-100">Read More Blog</Link>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Ourblog;