// ** React Imports
import { useState, useEffect } from "react";
import axios from "axios";

// ** MUI Imports
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import SquareIcon from "@mui/icons-material/Square";
import CircleIcon from "@mui/icons-material/Circle";

const RatingQuestions = ({ value, setValue, subjectId, raterId, question, surveyRatings, labels, filledAnswers, setFilledAnswers, setError }) => {

    const [hover, setHover] = useState(-1)

    const RatingTotal = surveyRatings ? surveyRatings.rating : 5;

    const getLabelText = (value) => {
        return `${value} ${surveyRatings.ratingIcon}${value !== 1 ? "s" : ""}, ${labels[value]}`;
    };

    const handleRatingChange = (questionId, newValue) => {   
        setError('')
        setValue(newValue);
        setFilledAnswers(filledAnswers => {
            const existingAnswerIndex = filledAnswers.findIndex(answer => answer.questionId === questionId);
    
            if (existingAnswerIndex !== -1) {
                const updatedAnswers = [...filledAnswers];
                
                if(newValue === null ){
                    updatedAnswers.splice(existingAnswerIndex, 1);
                }
                else{
                    updatedAnswers[existingAnswerIndex] = {
                        subjectId: subjectId,
                        questionId: questionId,
                        answer: newValue,
                        answerBy: raterId && raterId != '' ? raterId : subjectId,
                        isSelf: raterId && raterId != '' ? '0' : '1',
                    };
                }
                return updatedAnswers;
            } else {
                const newAnswer = {
                    subjectId: subjectId,
                    questionId: questionId,
                    answer: newValue,
                    answerBy: raterId && raterId != '' ? raterId : subjectId,
                    isSelf: raterId && raterId != '' ? '0' : '1',
                };
                return [...filledAnswers, newAnswer];
            }
        });
        console.log("filledAnswers=======",filledAnswers)

    };
    
    const filledValue = (questionId) => {
        console.log("questionId",questionId)
        console.log("question",question)
        console.log("filledAnswers",filledAnswers)
        const existingAnswerIndex = filledAnswers.findIndex(answer => answer.questionId === questionId);
        console.log("existingAnswerIndex",existingAnswerIndex)
        if (existingAnswerIndex !== -1) {
            setValue(filledAnswers[existingAnswerIndex].answer);
            return filledAnswers[existingAnswerIndex].answer
        }
        else{
            return 0
        }
    }; 
    
    const selectEmptyIcon = () => {
        switch (surveyRatings.ratingIcon) {
            case "Square":
                return <SquareIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            case "Circle":
                return <CircleIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            case "Star":
                return <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            default:
                return '';
        }
    }
    const selectRatingIcon = () => {
        switch (surveyRatings.ratingIcon) {
            case "Square":
                return <SquareIcon />;
            case "Circle":
                return <CircleIcon />;
            case "Star":
                return <StarIcon />;
            default:
                return '';
        }
    }

    return (
        <div>
            <Rating
                name={`rating-feedback`}
                value={filledValue(question.question.id)}
                precision={1}
                max={RatingTotal}
                getLabelText={getLabelText}
                onChange={(event, newValue) => handleRatingChange(question.question.id, newValue)}
                emptyIcon={selectEmptyIcon()}
                icon={selectRatingIcon()}
                title={(value) => getLabelText(value)}
                onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }}
            />
            {value !== null && (
                <Box sx={{fontSize:'12px', marginLeft: '30px', textAlign:'right'}}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
        </div>
    );
};

export default RatingQuestions;