import React, {  useCallback, useEffect, useMemo, useState } from "react";
import UserLayout from '../Layout/UserLayout'
import Card from "@mui/material/Card";
import { useParams } from 'react-router-dom';
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';

// ** MUI Imports
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Radio, RadioGroup } from "@mui/material";

import {Paper, Table, TableRow, TableHead, TableBody, TableCell, TableContainer} from '@mui/material';
import InviteCoworker from "./InviteCoworker";
import Link from "../../theme/overrides/link";

import MultiSelect from "../Common/MultiSelect"

const validationSchema = Yup.object({
    type: Yup.string().required('Type is required'),
    name: Yup.string().required('Name is required'),
    surveyId: Yup.string().required('Please select a survey type'),
    userIds: Yup.array().min(1, 'Please select at least one user'),
});

export const Invite = () => {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const { sessionId } = useParams();
    const [users, setUsers] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [surveySettings, setSurveySettings] = useState([]);
    const [currentSession, setCurrentSession] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [coworkers, setCoworkers] = useState([]);
    const [error, setError] = useState('');
    const [selectedUsersToInvite, setSelectedUsersToInvite] = useState([]);
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [usersSelectedToInvite, setUsersSelectedToInvite] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUsersLoaded, setSelectedUsersLoaded] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const initialValues = {
        sessionId: sessionId,
        name: '',
        surveyId: '',
        surveyRatingSettingId: '',
        type: '',
        users: [],
    };
        
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values); 
        },
      });

    useEffect(() => {
    if (selectedUsers.length > 0) {
        setSelectedUsersLoaded(true);
    }
}, [selectedUsers]);

    const managerIdArray = useMemo(() => {
        if (Object.keys(currentSession).length !== 0) {
            const userSessionUserIds = currentSession.user_sessions.map(session => session.userId);
            const managerIds =  users
                .filter(user => userSessionUserIds.includes(user.id))
                .map(user => ({ userId: user.id, managerId: user.managerId }));
                formik.setValues({
                    sessionId: sessionId,
                    name: currentSession.name,
                    surveyId: currentSession.surveyId,
                    surveyRatingSettingId: currentSession.surveyRatingSettingId,
                    type: currentSession.type,
                    users: managerIds,
                });
                // console.log("managerIds==",managerIds)
                return managerIds;
        }
        return [];
    }, [currentSession, users]);
    // useEffect(() => {
    //     if (Object.keys(currentSession).length !== 0) {
    //         const userSessionUserIds = currentSession.user_sessions.map(session => session.userId);
    //         const managerIdArray = users
    //             .filter(user => userSessionUserIds.includes(user.id))
    //             .map(user => ({ userId: user.id, managerId: user.managerId }));            

    //             formik.setValues({
    //                 sessionId: sessionId,
    //                 name: currentSession.name,
    //                 surveyId: currentSession.surveyId,
    //                 surveyRatingSettingId: currentSession.surveyRatingSettingId,
    //                 type: currentSession.type,
    //                 users: managerIdArray,
    //             });
    //             console.log("managerIdArray",managerIdArray)
    //     }
    //   }, [currentSession]);

    const getSessionDetails = useCallback(() => {
        axios.get(`/api/session/details?id=${sessionId}`).then(function (response) {
            let result = response.data;
            if(result.success){
                setCurrentSession(result.data);
                setSelectedUsers(result.data.user_sessions.map(item => ({
                    id: item.userId,
                    name: item.user.name,
                    managerId: item.user.managerId
                })))
            }
        });
    }, [sessionId]);
    
    useEffect(() => {
        const fetchData = async () => {
            await getSessionDetails();
            await getUsersList();
            await getList();
            await getSurveySettingList();
        };
    
        fetchData();
    }, [getSessionDetails]);
    

    const getList = async () => {
        await axios.get("/api/surveys").then(function (response) {
            let result = response.data;
            if (result.success) {
                setSurveys(result.data);
            } else {
                // setIsError(true);
            }
        });
    };
    const getSurveySettingList = async () => {
        await axios.get(`/api/surveyratingsettings?createdBy=${user.id}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                let idFound = 0;
                for (const item of result.data) {
                    if (item.createdBy !== user.id) {
                      idFound = item.id;
                      break;
                    }
                }
                formik.values.surveyRatingSettingId = idFound
                setSurveySettings(result.data);
            } else {
                // setIsError(true);
            }
        });
    };

    const getUsersList = async () => { 
        await axios.get("/api/users?isInviteComapnyUsers=1").then(function (response) {
            let result = response.data;
            if (result.success) {
                setUsers(result.data);
            } else {
                // setIsError(true);
            }
        });
    };

    // const handleMultiSelectChange = (selectedValues) => {
    //     console.log("handleMultiSelectChange===========",selectedValues)
    //     formik.setFieldValue('users', selectedValues);
    //   };
    const handleSubmit = (values) => {
        console.log("hanbdleSubmitValue===========",values)
        // find users who were sent invitation earlier but now removing
        const removedUsers =  selectedUsers.filter(itemA => !values.users.some(itemB => itemA.id === itemB.userId))

        values.removedUsers = removedUsers;
        axios.post(`api/userselfevaluation/create`, values).then(res => {
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    formik.setSubmitting(false);
                    getSessionDetails();
                })                
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            Swal.fire(error.response.data.message, '', 'error')
        });
    };

    // const handleCheckboxChange = (userId, managerId) => {
    //     formik.values.users = formik.values.users || [];
    //     const userIndex = formik.values.users.findIndex((user) => user.userId === userId);
    
    //     console.log("formik.values.users",formik.values.users)
    //     if (userIndex !== -1) {
    //         formik.values.users.splice(userIndex, 1);
    //         if(formik.values.users.length === 0){
    //             setUsersSelectedToInvite(false)
    //         }
    //     } else {
    //         formik.values.users.push({ userId, managerId });
    //         if(formik.values.users.length > 0){
    //             setUsersSelectedToInvite(true)
    //         }
    //     }
    
    //     formik.setFieldValue('users', [...formik.values.users]);
    // };
    const handleCheckboxChange = (userId, managerId) => {
        console.log("managerIdArray111111111===",managerIdArray)
        formik.values.users = formik.values.users || [];
        const userIndex = formik.values.users.findIndex((user) => user.userId === userId);
    
        if (userIndex !== -1) {
            formik.values.users.splice(userIndex, 1);
        } else {
            formik.values.users.push({ userId, managerId });
        }
    
        // Check if users contains users other than those in managerIdArray
        const hasOtherUsers = users.some(user => {
            const isInManagerIdArray = managerIdArray.some(manager => manager.userId === user.userId);
            return !isInManagerIdArray;
        });
        console.log("hasOtherUsers111111111===",hasOtherUsers)

        setUsersSelectedToInvite(users.length > 0 && hasOtherUsers);
    
        formik.setFieldValue('users', [...formik.values.users]);
    };

    const handleEmployeeSelect = (userId) => {
        setShow(true)
        setSelectedUsersToInvite([]);
        
        setSelectedEmployee(userId);
        getOtherUsersInvitedForSurvey(userId);
        fetchEmployeeCoworkers(userId);
    };
    const fetchEmployeeCoworkers = async(emp_id) => {
        await axios.get('/api/getCoworkers?id='+emp_id)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setCoworkers(result.data)
            }
            else {
                // setIsError(true);
            }
        })      
    }

    const getOtherUsersInvitedForSurvey = (selectedEmployeeId=null) => {
        const data = {
            users: selectedUsersToInvite,
            sessionId: sessionId,
            userId: selectedEmployeeId ? selectedEmployeeId : selectedEmployee, 
            surveyId: currentSession.surveyId
        }
        axios.post(`api/userselfevaluation/getOtherUsersInvitedForSurvey`, data).then(res => {
            if(res.data.success){                
                // setOtherUsersInvitedForSurvey(res.data.data);   
                setSelectedUsersToInvite(res.data.data)                 
                setInvitedUsers(res.data.data)              
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            Swal.fire(error.response.data.message, '', 'error')
        });
    }

    const handleRemoveInvitaion = async(userId, managerId) => {
        console.log("userId",userId)
        const data = {
            userId: userId,
            managerId: managerId,
            sessionId: parseInt(sessionId),
            surveyId: currentSession.surveyId,
        }
        await axios.post("/api/userselfevaluation/removeUserSurveyInvitation", data).then(function (response) {
            let result = response.data;
            if (result.success) {
                getSessionDetails();
            } else {
                // setIsError(true);
            }
        });
    }
     return (
        <UserLayout>
            <div className="col-md-8">
                <h1 className="mb-3">Invite User</h1>
                <form autoComplete="off" onSubmit={formik.handleSubmit} className="text-left mb-3">
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                listboxprops={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                }}
                                fullWidth
                                label="Session Name"
                                name="name"
                                size='small'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                disabled={currentSession.user_sessions && currentSession.user_sessions.length>0}                                
                            >
                            </TextField>
                            {formik.touched.name && Boolean(formik.errors.name) && (
                                <div className="text-danger">{formik.errors.name}</div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                listboxprops={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                }}
                                fullWidth
                                select
                                label="Select Survey Type"
                                name="surveyId"
                                size='small'
                                value={formik.values.surveyId}
                                onChange={formik.handleChange}
                                disabled={currentSession.user_sessions && currentSession.user_sessions.length>0}                                
                            >
                                <MenuItem value="" disabled>
                                    Please Select
                                </MenuItem>
                                {surveys.length > 0 ? (
                                    surveys.map((survey) => (
                                        <MenuItem key={survey.id} value={survey.id}>
                                            {survey.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" disabled>
                                        No surveys available
                                    </MenuItem>
                                )}
                            </TextField>
                            {formik.touched.surveyId && Boolean(formik.errors.surveyId) && (
                                <div className="text-danger">{formik.errors.surveyId}</div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField 
                            listboxprops={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                              }}
                              sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                              }}
                            variant={"outlined"}
                            style={{width: "100%"}}
                            fullWidth
                            select 
                            label='Select Survey Setting' 
                            name="surveyRatingSettingId" 
                            size='small'
                            value={formik.values.surveyRatingSettingId}
                            onChange={formik.handleChange}
                            disabled={currentSession.user_sessions && currentSession.user_sessions.length>0} 
                            error={formik.touched.surveyRatingSettingId && Boolean(formik.errors.surveyRatingSettingId)}
                            helperText={formik.touched.surveyRatingSettingId && formik.errors.surveyRatingSettingId}
                            >
                                <MenuItem value="" disabled> Please Select </MenuItem>
                                {surveySettings.length > 0 ? (
                                    surveySettings.map((setting) => (
                                        <MenuItem key={setting.id} value={setting.id}>{setting.name}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" disabled>
                                        No setting available
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid>                        
                    </Grid>
                
                    <Grid item xs={12} sm={12} className="mt-2">
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="type"
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                error={formik.touched.type && Boolean(formik.errors.type)}
                                
                                disabled={currentSession.user_sessions && currentSession.user_sessions.length>0}
                            >
                                <FormControlLabel value="180" control={<Radio />} label={<span style={{ fontSize: '14px', fontWeight: 600 }}>180 Assessment</span>} 
                            disabled={currentSession.user_sessions && currentSession.user_sessions.length>0}/>
                                <FormControlLabel value="360" control={<Radio />} label={<span style={{ fontSize: '14px', fontWeight: 600 }}>360 Assessment</span>} 
                            disabled={currentSession.user_sessions && currentSession.user_sessions.length>0}/>
                            </RadioGroup>
                            {formik.touched.type && formik.errors.type && (
                                <div style={{ color: "red" }}>{formik.errors.type}</div>
                            )}
                        </FormControl>
                        {formik.values.type === '180' && (
                            <div className="notes mb-3">
                            {/* Content to show when 180 Assessment is selected */}
                            <p className="fs-12 mb-0 ms-2"><span className="fw-bold me-1">Note:</span>Involves self-assessment and feedback from Supervisors/Managers.</p>
                            </div>
                        )}
                        {formik.values.type === '360' && (
                            <div className="notes mb-3">
                            {/* Content to show when 360 Assessment is selected */}
                            <p className="fs-12 mb-0 ms-2"><span className="fw-bold me-1">Note:</span>Gathers feedback from multiple stakeholders (e.g., supervisors, peers, direct reportees) for a comprehensive view.</p>
                            </div>
                        )}
                    </Grid>

                    <h1 className="mt-3 mb-2">Select Subject (People who are going to be evaluated)</h1>
                    <div className="bg-white rounded p-3 mb-3">
                        <Grid item xs={12} sm={12}>
                            {
                                selectedUsersLoaded ? 
                                <MultiSelect 
                                    formik={formik}
                                    optionsList={users ?
                                        users.map(user => ({
                                            id: user.id,
                                            name: user.name,
                                            managerId: user.managerId
                                        }))
                                    :[]} 
                                    defaultValue={selectedUsers}
                                />
                                : ''
                            }
                            
                            {/* <Grid item xs={12} sm={12} className="text-end"> */}
                                <Button className="text-end" style={{marginRight:15}} size='small' type="submit" variant="contained" disabled={formik.isSubmitting}>
                                    Send Invite
                                </Button>
                            {/* </Grid> */}

                            {/* <Grid>
                                <div className="row Rjscroll">
                                    {users.map((user) => (
                                        <div className="col-md-4 mb-2" key={user.id}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={() => handleCheckboxChange(user.id, user.managerId)}
                                                            checked={
                                                                Array.isArray(formik.values.users) &&
                                                                formik.values.users.some((u) => u.userId === user.id)
                                                            }
                                                            disabled={
                                                                Array.isArray(currentSession.user_sessions) &&
                                                                currentSession.user_sessions.some((u) => u.userId === user.id)
                                                            }
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            name="userIds"
                                                        />
                                                    }
                                                    label={<span style={{ fontSize: '13px' }}>{user.name}</span>}
                                                />
                                            </FormGroup>                                                
                                            </div>
                                        ))}
                                        {formik.touched.userIds && formik.errors.userIds && (
                                            <div className="text-danger fs-13">{formik.errors.userIds}</div>
                                        )}                                    
                                </div>
                                <Grid item xs={12} sm={12} className={usersSelectedToInvite ? "text-end d-block":" text-end d-none"}>
                                    <Button style={{marginRight:15}} size='small' type="submit" variant="contained" disabled={formik.isSubmitting}>
                                        Send Invite
                                    </Button>
                                </Grid>
                            </Grid> */}
                        </Grid> 
                    </div>
                </form>
                
                {currentSession.type === '360' && currentSession.user_sessions.length > 0  ?
                <>
                    {/* <hr/> */}
                    <h1 className="mb-2">Reviewers</h1>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Users</TableCell>
                                <TableCell>Managers</TableCell>
                                <TableCell>Peers</TableCell>
                                <TableCell>Direct Reportees</TableCell>
                                <TableCell>Others</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {currentSession.user_sessions.map((row) => (
                                <TableRow key={""+row.user.id}>
                                    <TableCell>
                                        <span className="fs-13 fw-500 ft-color ft" style={{cursor:"pointer"}} onClick={() => handleEmployeeSelect(row.user.id)}>
                                            {row.user.name}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        {row.invitedCoworkers.length !== 0 && row.invitedCoworkers.manager ? 
                                            <div className="name-container">
                                                {row.invitedCoworkers.manager.name}
                                                <CloseIcon className="close-icon" onClick={() => {handleRemoveInvitaion(row.user.id, row.invitedCoworkers.manager.id)}}/>
                                            </div>
                                         :'-'}
                                    </TableCell>
                                    <TableCell>
                                        { row.invitedCoworkers.peers !== undefined && row.invitedCoworkers.peers.length > 0 ?
                                            
                                            row.invitedCoworkers.peers.map((peer, index) => (      
                                                // peer.name + (index < row.invitedCoworkers.peers.length - 1 ? ', ': '')
                                                <div className="name-container">
                                                    {peer.name}
                                                    <CloseIcon className="close-icon" onClick={() => {handleRemoveInvitaion(row.user.id, peer.id)}}/>
                                                </div>
                                            ))
                                        :'-' }
                                    </TableCell>
                                    <TableCell>
                                        {row.invitedCoworkers.directReportees !== undefined && row.invitedCoworkers.directReportees.length > 0 ?
                                            row.invitedCoworkers.directReportees.map((directReportee, index) => (                                        
                                                //directReportee.name + (index < row.invitedCoworkers.directReportees.length - 1 ? ',': '')
                                                <div className="name-container">
                                                    {directReportee.name}
                                                    <CloseIcon className="close-icon" onClick={() => {handleRemoveInvitaion(row.user.id, directReportee.id)}}/>
                                                </div>
                                            ))
                                        :'-'}
                                    </TableCell>
                                    <TableCell>
                                        {row.invitedCoworkers.otherEvaluators !== undefined && row.invitedCoworkers.otherEvaluators.length > 0 ?
                                            row.invitedCoworkers.otherEvaluators.map((otherEvaluator, index) => (                                        
                                                // otherEvaluator.name + (index < row.invitedCoworkers.otherEvaluators.length - 1 ? ',': '')                                                
                                                <div className="name-container">
                                                    {otherEvaluator.name}
                                                    <CloseIcon className="close-icon" onClick={() => {handleRemoveInvitaion(row.user.id, otherEvaluator.id)}}/>
                                                </div>
                                            ))
                                        :'-'}
                                    </TableCell>
                                </TableRow>
                            ))} 
                            </TableBody>
                        </Table>
                    </TableContainer>                    
                </>:''
            }
          </div>
          <InviteCoworker show={show} handleClose={handleClose} coworkers={coworkers} selectedEmployee={selectedEmployee} selectedUsersToInvite={selectedUsersToInvite} setSelectedUsersToInvite={setSelectedUsersToInvite} currentSession={currentSession} getOtherUsersInvitedForSurvey={getOtherUsersInvitedForSurvey} invitedUsers={invitedUsers} setInvitedUsers={setInvitedUsers} getSessionDetails={getSessionDetails}/>
        </UserLayout>

    );
};

export default Invite;
