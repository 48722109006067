import React from 'react';
import { Bar } from "react-chartjs-2";
import { BarElement,  CategoryScale,Chart as ChartJS,Legend, LinearScale,Title, Tooltip } from "chart.js";
// import "chartjs-plugin-datalabels";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement,Title,Tooltip,Legend,ChartDataLabels);

const BarChart = ({chartData}) => {
  const transformedData = Object.fromEntries(
    Object.entries(chartData).map(([key, value]) => [key.charAt(0).toUpperCase() + key.slice(1), value])
  );

  const options = {
    scales: {
      x: { 
        max : 5,    
        min : 0,
        ticks: { display: false },
        grid: { display: false } },
      // y: { grid: { display: false } },
    },
    indexAxis:'y',
    elements:{ bar:{ borderWidth:1} },
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false, text: 'Detailed Data' },  
      tooltip: {
        enabled: false
      },  
    },
  };
  
  const data = {
    labels: Object.keys(transformedData),
    datasets: [ 
      {
        backgroundColor: ["#eb5151", "#2e689a", "#efc400", "#fd2f9c"], 
        borderColor: ["#eb5151", "#2e689a", "#efc400", "#fd2f9c"],//"#eb5151",
        borderWidth: 1,
        hoverBackgroundColor: ["#eb5151", "#2e689a", "#efc400", "#fd2f9c"],//"rgba(255,99,132,0.4)",
        // hoverBorderColor: "rgba(255,99,132,1)",
        data: Object.values(chartData),        
        datalabels:{color: "black"}
      },
    ]
  }

  const plugins = [ChartDataLabels]

  return <Bar options={options} plugins={plugins} data={data} />;
}

export default BarChart;