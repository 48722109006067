import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import TableStickyHeader from '../Common/TableStickyHeader'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import axios from 'axios'
import Swal from 'sweetalert2'
import Add from './Add'
import UserLimit from './UserLimit'
import UploadExcel from './UploadExcel'
import 'react-tabs/style/react-tabs.css';

export default function List() {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showexcelform, setShowexcelform] = useState(false);
    const [popupType, setPopupType] = useState('Add');
    const [editId, setEditId] = useState(0);
    const [users, setUsers] = useState([]);
    const [usedUserCount, setUsedUserCount] = useState(0);
    const [allowedUserCount, setAllowedUserCount] = useState(0);

    
    const [userLimitPopupShow, setUserLimitPopupShow] = useState(false);
    useEffect(() => {
        if(user.role !== 'Admin'){
            navigate('/dashboard')
        }
        getList();
        getUsersLimit();
    }, [])

    const getList = async() => {
        await axios.get(`/api/subjects`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setUsers(result.data)
                setUsedUserCount(result.data.length)
            }
        })
    }

    const getUsersLimit = async() => {
        await axios.get(`/api/users/getUsersLimit`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setAllowedUserCount(result.data)
            }
        })
    }

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }
    const handleUpload = () => {
        setEditId(0);
        setPopupType('UploadExcel');
        setShowexcelform(true);
    }
    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }
    
    
    const handleUserLimitPopupClose = () => setUserLimitPopupShow(false);
    
    const handleLimit = () => {
        setUserLimitPopupShow(true);
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/subject?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')
                        getList();
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleStatus = (e, id) => {       
        var status = '1';
        if(e.target.checked === true) {
            status = '1'
        }
        else {
            status = '0'
        }
        axios.put(`api/users/updateStatus?id=${id}`, {status}).then(res => {                
        })
        .catch(err => {
        })
    }
    const handleUserLogin = (email) => {
        // Send a POST request to your API endpoint with the email as data
        axios.post('/api/auth/another/login', { email: email })
          .then((res) => {
            if (res.data.success) {
              localStorage.clear();
              localStorage.setItem('authUser', JSON.stringify(res.data.data));
              navigate('/dashboard');
            } else {
              Swal.fire(res.data.message, '', 'info');
            }
          })
          .catch((error) => {
            Swal.fire(error.message, '', 'info');
          });
      };

    const columns = [
        { id: 'name', label: 'Name', minWidth: 120 },
        { id: 'email', label: 'Email', minWidth: 150 },
        { id: 'action', label: 'Action'}
    ]

    return (
        <UserLayout>
            <div className="row">
                <div className='col-md-4 col-6 mb-3'>
                    <h1>Subjects</h1>
                </div>
                <div className='col-md-8 col-12 text-md-end mb-3'>
                    <Button size='small' variant='contained' sx={{ marginRight: 3.5 }} onClick={ ((usedUserCount < allowedUserCount) ? handleUpload : handleLimit) }>Upload Excel</Button>
                    <Button size='small' variant='contained' onClick={ ((usedUserCount < allowedUserCount) ? handleAdd : handleLimit) }>Add Subject</Button>
                    { Math.round(((70/100)*allowedUserCount)) <= usedUserCount ? 
                        (<p className="mt-2 text-danger" style={{fontSize:'12px'}}>*Your max subjects limit is about to expire, you can upgrade your plan to add more subjects.</p>)
                    : "" }
                </div>
            </div>
            <Grid container spacing={6}>            
                <Grid item xs={12}>
                    <Card>
                        <TableStickyHeader columns={columns} rows={users} setEditId={setEditId} setPopupType={setPopupType} setShow={setShow} handleEdit={handleEdit} handleDelete={handleDelete} handleStatus={handleStatus} handleUserLogin={handleUserLogin}/>
                    </Card>
                </Grid>           
            </Grid>

            <Add type={popupType} show={show} setShow={setShow} getList={getList} editId={editId} setEditId={setEditId}/>
            <UploadExcel user={user} show={showexcelform} setShow={setShowexcelform} getList={getList}/>
            <UserLimit userLimitPopupShow={userLimitPopupShow} setUserLimitPopupShow={setUserLimitPopupShow} handleUserLimitPopupClose={handleUserLimitPopupClose}/>

        </UserLayout>
    )
}