// ** React Imports
import { useState, useEffect } from "react";
import axios from "axios";


const SingleChoiceQuestions = ({ index, answerKey, survey_competency_question, currentSessionDetails }) => {
   
    const [radioAnswers, setRadioAnswers] = useState({});
    
    // For radio button inputs
    const handleRadioChange = (surveyIndex, questionId, event) => {
        setRadioAnswers((prevState) => ({
            ...prevState,
            [`${surveyIndex}_${questionId}`]: event.target.value,
        }));
    };


    return (<div className="col-md-3 text-end">
        <div className="col-md-3 text-end">
            <div className="button-wrap">
                {/* <input className="hidden radio-label" type="radio" name="yes-button" id="yes-button" />
                <label className="button-label" for="yes-button">
                    <span>Yes</span>
                </label>
                <input className="hidden radio-label" type="radio" name="no-button" id="no-button" />
                <label className="button-label" for="no-button">
                    <span>No</span>
                </label> */}
                <input className="hidden radio-label" type="radio" name={`radio-button-${answerKey}`} id={`yes-button`} value="Yes"
                    onChange={(event) => handleRadioChange(index, survey_competency_question.id, event)}
                />
                <label className="button-label" htmlFor={`yes-button`}>
                    <span>Yes</span>
                </label>
                <input className="hidden radio-label" type="radio" name={`radio-button-${answerKey}`} id={`no-button`} value="No" onChange={(event) => handleRadioChange(index, survey_competency_question.id, event)} />
                <label className="button-label" htmlFor={`no-button`}>
                    <span>No</span>
                </label>
            </div>
        </div>
    </div>);
};

export default SingleChoiceQuestions;