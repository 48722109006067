import { Link } from "react-router-dom";

export const Header = () => {
    return (
        <header className="header fixed-top">
        
            <nav className="navbar navbar-expand-lg navbar-light py-3">
                <div className="container"><a className="navbar-brand">
                <img src="./images/logo.png" alt="..." width="150"/></a>
                    <button className="navbar-toggler navbar-toggler-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto d-flex align-items-center">
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/">Home</Link>
                            </li>
                            <li className="nav-item me-4">
                                <Link className="nav-link" to="/about">About us</Link>
                            </li>                           
                            <li className="nav-item me-4">
                                <a className="nav-link" href="#!">Contact us</a>
                            </li>
                            <li className="nav-item me-4">
                                <Link to="/login" className="btn btn-secondary">Login</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/signup" className="btn btn-theme">Signup</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;