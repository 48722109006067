import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend );

const LineChart = ({chartData}) => {

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Summary of Domains',
      },
    },
  };
  
  // chartData.map((chartD) =>{
    // console.log("chartDchartDchartDchartD",chartData);
  // })
  const selfAns = '';
  const otherAns = '';
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
  const data = {
    labels,
    datasets: [
      {
        label: 'Self',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),//selfAns,//
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Others',
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),//otherAns,//
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return <Line options={options} data={data} />;
}

export default LineChart;