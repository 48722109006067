import { useEffect, useState } from "react";
import axios from "axios";

export const Plans = () => {
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        getList()
    }, [])

    const getList = async() => {
        await axios.get('/api/plans?isPublic=1')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setPlans(result.data)
            }
        })
    }

    return (
        <section className="py-5">
        <div className="container">
            <div className="row text-center mb-5">
                <div className="col-lg-7 mx-auto">
                    <p className="h6 text-uppercase text-theme">Pricing</p>
                    <h2 className="mb-3">Not any hidden charge, Choose Your pricing plan</h2>
                    <p className="text-muted mb-4">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor invidunt</p>
                </div>
            </div>
            <div className="row align-items-center gx-lg-0 gy-4">
                {
                    plans.map((plan) => {
                        return (
                            <div className="col-lg-3">
                                <div className="card shadow">
                                    <div className="card-body text-center d-flex align-items-center flex-column py-5 p-lg-5">
                                        <p className="h5 mb-2 text-uppercase">{plan.name}</p>
                                        <p className="text-sm text-gray">Upto {plan.noOfUsers} Users / Monthly Package</p>
                                        {/* <ul className="list-unstyled mb-4 text-muted">
                                            <li className="mb-1">50GB Bandwidth </li>
                                            <li className="mb-1">Business &amp; Finance Analysing </li>
                                            <li className="mb-1">24 hour support </li>
                                            <li className="mb-1">Customer Management </li>
                                        </ul> */}
                                        <h3 className="table-price mb-4"><sup>$</sup>{plan.price}</h3>
                                        <a className="btn btn-theme" href="./signup">Subscribe</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </section>
    )
}

export default Plans;