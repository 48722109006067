// ** React Imports
import { useState } from 'react'
import {styled, Switch, Accordion, AccordionDetails, AccordionSummary, Typography, IconButton, Tooltip, Grid} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useLocation } from 'react-router-dom'
import Add from "../Competency/Add";
import EditQuestion from "../Question/EditQuestion"
import Swal from 'sweetalert2'
import axios from 'axios'
import Question from "../Common/Question";
// import Link from '@mui/material/Link'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const Competency = ({competencies, getList, ...rest}) => {
  // ** States
  const authUser = localStorage.getItem('authUser');
  const authUserId = JSON.parse(authUser).id;
  
  const [competencyExpanded, setCompetencyExpanded] = useState(false);
  
  const [show, setShow] = useState(false);
  const [popupType, setPopupType] = useState("Edit");
  const [editId, setEditId] = useState(0);
  const [showQuestion, setShowQuestion] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState(0);

  const location = useLocation();
  const current_path = location.pathname;

  let data = competencies;
  if(current_path === '/surveys'){
    data = competencies.survey_competencies;
  }

  const handleCompetencyExpand = (panel) => (event, isExpanded) => {
    setCompetencyExpanded((isExpanded) ? panel : false);
  };
  

  const handleEdit = (id) => {
    setEditId(id);
    setPopupType('Edit');
    setShow(true);
  }
  const handleClose = () => setShow(false);

  const handleQuestionClose = () => setShowQuestion(false);
  
  const handleCompetencyStatus = (e, competencyId) => {
    var status = '1';
    if(e.target.checked === true) {
        status = '1'
    }
    else {
        status = '0'
    }
    axios.put(`api/competencies/updateStatus?id=${competencyId}`, {status}).then(res => {                
    })
    .catch(err => {
    })
  }
  
  const handleCompetencyDelete = (competencyId) => {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) { 
          axios.delete(`/api/competencies/delete?id=${competencyId}`).then((res) =>{
              if(res.data.success){                             
                  Swal.fire('Deleted!', '', 'success').then(result => { 
                      getList();
                  })
              }
              else{
                Swal.fire(res.data.message, '', 'info')
              } 
              
          }).catch((error) => {
              console.log(error);                     
              Swal.fire(error.message, '', 'info')           
          });
        } else if (result.isDenied) {
          Swal.fire('Cancelled!', '', 'info')
        }
    }) 
  }

  return (
    <>
    <div>
      {data.map((competency) => (
      <Accordion
          key={competency.id}
          expanded={competencyExpanded === 'panel' + competency.id}
          onChange={handleCompetencyExpand('panel' + competency.id)}
      >
        <AccordionSummary sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} expandIcon={<ExpandMoreIcon />} aria-controls={`panel${competency.id}-content`} id={`panel${competency.id}-header`}>
          <Grid sx={{width: '20%'}} md={{width: '75%'}}>
            <Typography className='fw-500'>{competency.name ? competency.name : competency.competency.name}  </Typography>
          </Grid>
          <Grid sx={{width: '80%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}} md={{width: '25%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>

            {current_path === '/competencies' && authUserId === competency.userId ? 
              <IOSSwitch size='small' onChange={(e) => handleCompetencyStatus(e, competency.id)} defaultChecked={competency.status === '1' ? true : false}/>  : ""
            }
            {current_path === '/competencies' ?
              <Link to={"/add-questions/"+competency.id} title="Add Questions"> 
                  <Tooltip size='small' className='ss_view' sx={{ marginLeft: 2, marginRight: 2}}>
                      <IconButton size='small'><AddIcon /></IconButton>
                  </Tooltip>
              </Link> :""
            }
            { current_path === '/competencies' && authUserId === competency.userId ? 
              <>              
                <Tooltip onClick={() => handleEdit(competency.id)} title="Edit Competency" size='small' className='ss_edit' sx={{ marginRight: 2 }}>
                  <IconButton size='small'><EditIcon /></IconButton>
                </Tooltip>
                
                <Tooltip onClick={() => handleCompetencyDelete(competency.id)} title="Delete Competency" size='small' className='ss_delete' sx={{ marginRight: 2 }}> 
                    <IconButton size='small'><DeleteIcon /></IconButton> 
                </Tooltip>
              </> :""
            }
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

            <Question 
              questions={competency.survey_competency_questions ? competency.survey_competency_questions : competency.questions} 
              surveyId={competencies.id ? competencies.id : 0} 
              competencyId={competency.competencyId?competency.competencyId:competency.id}
              getList={getList}
            />
 
           </Typography>
          
        </AccordionDetails>
      </Accordion>
      ))}
    </div>

    <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId} />                    
    <EditQuestion showQuestion={showQuestion} setShowQuestion={setShowQuestion} handleQuestionClose={handleQuestionClose} getList={getList} editQuestionId={editQuestionId} setEditQuestionId={setEditQuestionId} />                    
    </>
    
  )
}

export default Competency
