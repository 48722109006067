import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Newsletter from "./components/Newsletter";
import Plans from "./components/Plans";
import Testimonial from "./components/Testimonial";
import Process from "./components/Process";
import Services from "./components/Services";
import Ourblog from "./components/Ourblog";

export default function Home() {
    return (
        <>
            <Header/>
            <Banner/>
            <Services />
            <Process/>
            <Plans />
            {/* <Testimonial /> */}
            <Ourblog />
            {/* <Newsletter/> */}
            <Footer/>            
        </>
        
    )     
}