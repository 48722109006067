import React, { useEffect }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2'
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    name: yup.string('Enter group name').required('Group Name is required')
});

export const Add = ({type, show, setShow, getList, editId, setEditId, ...rest}) => {
    const initialValues = {
        id: editId,
        name: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
        }
    });

    const handleClose = () => {
        formik.resetForm();
        setShow(false)
    };

    useEffect(() => {
        if(editId > 0){
            axios.get(`api/rater_group?id=${editId}`).then(res => {
                // console.log("res--------------",res.data.data);
                if(res.data.success){
                    formik.setFieldValue('id', res.data.data.id)
                    formik.setFieldValue('name', res.data.data.name)
                }
            })
        }
        else{
            formik.resetForm();
        }
        
    },[editId]);
    
    const handleSubmit = (values, setSubmitting) =>{
        if(editId > 0){
            axios.put(`api/rater_group`, values).then(res => {
                setSubmitting(false)
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                        // formik.setValues(initialValues)       
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                setSubmitting(false)
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`api/rater_group`, values).then(res => {
                setSubmitting(false)
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        // formik.setValues(initialValues)
                        formik.resetForm();
                        getList();
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                setSubmitting(false)
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }        
    }
    
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">{type} Rater Group</h5>
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth
                                listboxprops={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                }}
                                size='small'
                                id='name'
                                label='Name' 
                                placeholder='Name' 
                                value={formik.values.name} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>                      
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button size='small' type='reset' sx={{ marginRight: 3.5 }} variant='outlined' onClick={handleClose}>
                        Close
                    </Button>
                    <Button size='small' type="submit" variant='contained' disabled={formik.isSubmitting}>
                        {type == 'Add' ? 'Add' : 'Update'}
                    </Button>              
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};