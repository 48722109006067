// ** React Imports
// import { useState } from "react";
import { useState, useEffect } from "react";

// ** MUI Imports
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import LineChart from './charts/linechart'
import BarChart from './charts/barchart'
import axios from 'axios'

import DoughnutChart from './charts/doughnutchart'
import { Divider } from "@mui/material";


const CardNavigationCenter = ({currentUserId}) => {
  // ** State
  const [value, setValue] = useState("1");

  const [executiveSummary, setExecutiveSummary] = useState([]);
  const [domainSummary, setDomainSummary] = useState([]);
  const [highLowScore, setHighLowScore] = useState([]);
  const [positiveNegativeGap, setPositiveNegativeGap] = useState([]);
  const [detailedResults, setDetailedResults] = useState([]);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getExecutiveSummaryList();
    getDomainSummary();
    getHighandLowScoreList();
    getPositiveandNegativeGapList();
    getDetailedResults(); 
    // getSessionSettings();
  }, [currentUserId]);

  const getExecutiveSummaryList = async () => {
    try {
      const response = await axios.get(`/api/assessment/report/excutive_summary/${currentUserId}`);
      const result = response.data;
      if (result.success) {
        setExecutiveSummary(result.data);
        // console.log('result', result.data);
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  const getDomainSummary = async () => {
    try {
      const response = await axios.get(`/api/assessment/report/summary_domain/${currentUserId}`);
      const result = response.data;
      if (result.success) {
        setDomainSummary(result.data);
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  const getHighandLowScoreList = async () => {
    try {      
      const response = await axios.get(`/api/assessment/report/high_low_score/${currentUserId}`);
      const result = response.data;
      if (result.success) {
        setHighLowScore(result.data);
        // console.log('result', result.data);
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  const getDetailedResults = async () => {
    try {      
      const response = await axios.get(`/api/assessment/report/detailed_result/${currentUserId}`);
      const result = response.data;
      if (result.success) {
        setDetailedResults(result.data);
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  const getPositiveandNegativeGapList = async () => {
    try {      
      const response = await axios.get(`/api/assessment/report/possitive_negative_gap/${currentUserId}`);
      const result = response.data;
      if (result.success) {
        setPositiveNegativeGap(result.data);
        // console.log('result', result.data);
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }
  

  return (
    <TabContext value={value}>
      <TabList centered onChange={handleChange} aria-label="card navigation example">
        <Tab value="1" label="About"/>
        <Tab value="2" label="Executive Summary" />
        <Tab value="3" label="Summary of Domains" />
        <Tab value="4" label="High and Low" />
        <Tab value="5" label="Rating Gaps" />
        <Tab value="6" label="Detailed Results" />
        {/* <Tab value="7" label="Comments" /> */}
      </TabList>
        <TabPanel value="1">
        <h4 className="mb-3 mt-1 fs-20">About Report</h4>
        <div className="row">
              <div className="col-md-7">
                  <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-theme ft-light">WHAT THE REPORT MEANS TO YOU</TableCell>                                        
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>This information enables you to understand personal strengths as well as identify areas for potential improvement. The report also provides insights on your personal competency level in relation to others. The Description terms will allow you to interpret your results with additional detail.</TableCell>
                              </TableRow>                        
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr> 
                   <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-theme ft-light">DESCRIPTION OF TERMS</TableCell>                                        
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>To help you better understand your report, we have provided the following definitions for terms used throughout the report</TableCell>
                              </TableRow>                        
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr>  
                  <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">TERMS</TableCell>
                            <TableCell className="bg-secondary ft-light"></TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>CURRENT</TableCell>
                              <TableCell>The average of responses given during the current feedback cycle for each behavior, competency or domain.</TableCell>                                       
                          </TableRow>                       
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>PREVIOUS</TableCell>
                              <TableCell>The average of responses given during the previous feedback cycle for each behavior,competency or domain.</TableCell>                                       
                          </TableRow>                       
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>RATERGROUP</TableCell>
                              <TableCell>A group of raters who are at the same professional level with respect to you (PEERS, SUPERVISORS, DIRECT REPORTS).</TableCell>                                       
                          </TableRow>                       
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>GAP</TableCell>
                              <TableCell>The difference between the scores of two ratergroups or two time periods.</TableCell>                                       
                          </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr> 
              </div>
              <div className="col-md-5">
                {/* <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                              <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                              <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                              <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Almost Never</TableCell>
                                  <TableCell align='right'>1</TableCell>                                       
                                  <TableCell component='th' scope='row'>Frequently</TableCell>
                                  <TableCell align='right'>5</TableCell>                                       
                              </TableRow>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Rarely</TableCell>
                                  <TableCell align='right'>2</TableCell>                                       
                                  <TableCell component='th' scope='row'>Usually</TableCell>
                                  <TableCell align='right'>6</TableCell>                                       
                              </TableRow>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Occasionally</TableCell>
                                  <TableCell align='right'>3</TableCell>                                       
                                  <TableCell component='th' scope='row'>Almost Always</TableCell>
                                  <TableCell align='right'>7</TableCell>                                       
                              </TableRow>  
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Sometimes</TableCell>
                                  <TableCell align='right'>4</TableCell>
                                  <TableCell component='th' scope='row'></TableCell>
                                  <TableCell align='right'></TableCell>
                              </TableRow>                      
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr> */}
                  <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Total</TableCell>
                                  <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                              </TableRow>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Self</TableCell>
                                  <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                              </TableRow>                     
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr>
              </div>
            </div>                         
        </TabPanel>
        <TabPanel value="2">
          <h4 className="mb-3 mt-1 fs-20">Executive Summary</h4>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-theme ft-light" colSpan="2">KEY INSIGHTS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            {executiveSummary && executiveSummary.key_insights && executiveSummary.key_insights.length > 0 ? (
                              executiveSummary.key_insights.map((excutivedata, index) => (
                               
                                  <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"key_sights"+index}>
                                    <TableCell component='th' scope='row'>{excutivedata.question.competency.name}</TableCell>
                                    <TableCell align='right'>{excutivedata.question.name}</TableCell>
                                  </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={3}>No Record Found</TableCell>
                              </TableRow>
                            )}
                            
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                  <hr></hr> 
                  <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-secondary ft-light">TOP THREE RATED QUESTIONS</TableCell>
                              <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {executiveSummary && executiveSummary.top_question && executiveSummary.top_question.length > 0 ? (
                              executiveSummary.top_question.map((excutivedata, index) => (
                             
                                 <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}} key={"top_rated_questons"+index}>
                                  <TableCell component='th' scope='row'>{excutivedata.question.name}</TableCell>
                                  <TableCell align='right'>{excutivedata.score}</TableCell>                                       
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={3}>No Record Found</TableCell>
                              </TableRow>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr>
                  <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-secondary ft-light">TOP THREE LARGEST RATING GAPS</TableCell>
                              <TableCell className="bg-secondary ft-light" align='right'>OTHERS</TableCell>                    
                              <TableCell className="bg-secondary ft-light" align='right'>SELF</TableCell>                    
                              <TableCell className="bg-secondary ft-light" align='right'>GAP</TableCell>                    
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {executiveSummary && executiveSummary.rating_gap && executiveSummary.rating_gap.length > 0 ? (
                              executiveSummary.rating_gap.map((excutivedata, index) => (
                               <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}} key={"largest_rating_gap"+index}>
                                    <TableCell component='th' scope='row'>{excutivedata.domain}	</TableCell>
                                    <TableCell align='right'>{excutivedata.other_rate}</TableCell>                                       
                                    <TableCell align='right'>{excutivedata.self_rate}</TableCell>                                       
                                    <TableCell align='right'>{excutivedata.gap}</TableCell>                                       
                                  </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={3}>No Record Found</TableCell>
                              </TableRow>
                            )}
                         </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr>
                  <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-secondary ft-light">SUMMARY OF SCORES</TableCell>
                              <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total Score</TableCell>
                                <TableCell align='right'>
                                  {executiveSummary && executiveSummary.overallScore ? executiveSummary.overallScore.score : ''}
                                </TableCell>                                     
                              </TableRow>                       
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr>
                  {/* <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-secondary ft-light">AREAS OF IMPROVEMENT</TableCell>
                              <TableCell className="bg-secondary ft-light" align='right'>CURRENT</TableCell>                    
                              <TableCell className="bg-secondary ft-light" align='right'>PREVIOUS</TableCell>                    
                              <TableCell className="bg-secondary ft-light" align='right'>GAP</TableCell>                    
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>No Record available	</TableCell>
                                <TableCell align='right'>0.00</TableCell>                                       
                                <TableCell align='right'>0.00</TableCell>                                       
                                <TableCell align='right'>0.00</TableCell>                                       
                              </TableRow>                       
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr>   
                  <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-secondary ft-light">AREAS OF DECLINE</TableCell>
                              <TableCell className="bg-secondary ft-light" align='right'>CURRENT</TableCell>                    
                              <TableCell className="bg-secondary ft-light" align='right'>PREVIOUS</TableCell>                    
                              <TableCell className="bg-secondary ft-light" align='right'>GAP</TableCell>                    
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>No Record available	</TableCell>
                                <TableCell align='right'>0.00</TableCell>                                       
                                <TableCell align='right'>0.00</TableCell>                                       
                                <TableCell align='right'>0.00</TableCell>                                       
                              </TableRow>                       
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr>    */}
              </div>
              {/* <div className="col-md-5">
                <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                              <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                              <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                              <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Almost Never</TableCell>
                                  <TableCell align='right'>1</TableCell>                                       
                                  <TableCell component='th' scope='row'>Frequently</TableCell>
                                  <TableCell align='right'>5</TableCell>                                       
                              </TableRow>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Rarely</TableCell>
                                  <TableCell align='right'>2</TableCell>                                       
                                  <TableCell component='th' scope='row'>Usually</TableCell>
                                  <TableCell align='right'>6</TableCell>                                       
                              </TableRow>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Occasionally</TableCell>
                                  <TableCell align='right'>3</TableCell>                                       
                                  <TableCell component='th' scope='row'>Almost Always</TableCell>
                                  <TableCell align='right'>7</TableCell>                                       
                              </TableRow>  
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Sometimes</TableCell>
                                  <TableCell align='right'>4</TableCell>
                                  <TableCell component='th' scope='row'></TableCell>
                                  <TableCell align='right'></TableCell>
                              </TableRow>                      
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr>
                  <div className="col-md-12">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Total</TableCell>
                                  <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                              </TableRow>
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                  <TableCell component='th' scope='row'>Self</TableCell>
                                  <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                              </TableRow>                     
                          </TableBody>
                        </Table>
                    </TableContainer>
                  </div>
                  <hr></hr>
              </div> */}
            </div>                   
        </TabPanel>
        <TabPanel value="3">
          <h4 className="mb-3 mt-1 fs-20">Summary of Domains</h4>
          <div className="row">
            <div className="col-md-12">
                <div className="row">
                  {domainSummary && domainSummary.length > 0 ? (
                    domainSummary.map((summary, index) => (
                      <>
                      <div className="col-md-3" key={"summary_chart"+index}>                      
                        <DoughnutChart chartData={summary}/>
                      </div>
                      </>
                    ))
                  ) : ( 
                    // <div className="row">
                      <div className="col-md-12">
                        <TableCell>No Record Found</TableCell>
                      </div>
                    // </div>
                    // <div className="col-md-12">
                    // <TableContainer component={Paper}>
                    //   <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                    //     <TableCell component='th' scope='row'>No Record Found</TableCell>
                    //   </TableRow>
                    // </TableContainer>
                    // </div>
                   )}
                </div>
                <hr></hr> 
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-theme ft-light">DESCRIPTION</TableCell>                                        
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>These charts provide a visual summary of each domain for self, others and company average.</TableCell>
                            </TableRow>                        
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>                   
            </div>
            {/* <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total</TableCell>
                                <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Self</TableCell>
                                <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                            </TableRow>                     
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
            </div> */}
          </div>                         
        </TabPanel>
        <TabPanel value="4">
          <h4 className="mb-3 mt-1 fs-20">High and Low Scores</h4>
          <div className="row">
            <div className="col-md-12">
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-theme ft-light" colSpan="3">FIVE HIGHEST SCORES</TableCell>                                              
                          </TableRow>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">Domain</TableCell>
                            <TableCell className="bg-secondary ft-light">Question</TableCell>                    
                            <TableCell className="bg-secondary ft-light" align='right'>Total</TableCell>                   
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {highLowScore && highLowScore.heighest_score && highLowScore.heighest_score.length > 0 ? (
                            highLowScore.heighest_score.map((highlowdata, index) => (
                              
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}} key={"highest_score"+highlowdata.question.id}>
                                  <TableCell component='th' scope='row'>{highlowdata.question.competency.name}	</TableCell>
                                  <TableCell>{highlowdata.question.name}	</TableCell>                                       
                                  <TableCell align='right'>{highlowdata.avg_surveyAnswer}</TableCell>                                       
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>No Record Found</TableCell>
                            </TableRow>
                          )}
                          </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr> 
                  <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                        <TableRow>
                            <TableCell className="bg-theme ft-light" colSpan="3">FIVE LOWEST SCORES</TableCell>                                              
                          </TableRow>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">Domain</TableCell>
                            <TableCell className="bg-secondary ft-light">Question</TableCell>                    
                            <TableCell className="bg-secondary ft-light" align='right'>Total</TableCell>                   
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {highLowScore && highLowScore.lowest_score && highLowScore.lowest_score.length > 0 ? (
                            highLowScore.lowest_score.map((highlowdata, index) => (
                              
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}} key={"lowest_score"+highlowdata.question.id}>
                                  <TableCell component='th' scope='row'>{highlowdata.question.competency.name}	</TableCell>
                                  <TableCell>{highlowdata.question.name}	</TableCell>                                       
                                  <TableCell align='right'>{highlowdata.avg_surveyAnswer}</TableCell>                                       
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>No Record Found</TableCell>
                            </TableRow>
                          )}
                                                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>                   
            </div>
            {/* <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total</TableCell>
                                <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Self</TableCell>
                                <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                            </TableRow>                     
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
            </div> */}
          </div>                         
        </TabPanel>
        <TabPanel value="5">
          <h4 className="mb-3 mt-1 fs-20">Positive & Negative Gaps</h4>
          <div className="row">
            <div className="col-md-12">
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                        <TableRow>
                            <TableCell className="bg-theme ft-light" colSpan="5">Positive Gaps</TableCell>                                             
                          </TableRow>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">Domain</TableCell>
                            <TableCell className="bg-secondary ft-light">Question</TableCell>                    
                            <TableCell className="bg-secondary ft-light" align='right'>Others</TableCell>                   
                            <TableCell className="bg-secondary ft-light" align='right'>Self</TableCell>                   
                            <TableCell className="bg-secondary ft-light" align='right'>Gap</TableCell>                   
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {positiveNegativeGap && positiveNegativeGap.possitive && positiveNegativeGap.possitive.length > 0 ? (
                            positiveNegativeGap.possitive.map((ratinggapdata, index) => (
                              
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}} key={"positive_gap"+index}>
                                  <TableCell component='th' scope='row'>{ratinggapdata.domain}</TableCell>
                                  <TableCell>{ratinggapdata.question}</TableCell>                                       
                                  <TableCell align='right'>{ratinggapdata.other_rate}</TableCell>                                       
                                  <TableCell align='right'>{ratinggapdata.self_rate}</TableCell>                                       
                                  <TableCell align='right'>{ratinggapdata.gap}</TableCell>                                       
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>No Record Found</TableCell>
                            </TableRow>
                          )}
                          </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr> 
                  <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                        <TableRow>
                            <TableCell className="bg-theme ft-light" colSpan="5">Negative Gaps</TableCell>                                              
                                                                          
                          </TableRow>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">Domain</TableCell>
                            <TableCell className="bg-secondary ft-light">Question</TableCell>                    
                            <TableCell className="bg-secondary ft-light" align='right'>Others</TableCell>                   
                            <TableCell className="bg-secondary ft-light" align='right'>Self</TableCell>                   
                            <TableCell className="bg-secondary ft-light" align='right'>Gap</TableCell>                   
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {positiveNegativeGap && positiveNegativeGap.negative && positiveNegativeGap.negative.length > 0 ? (
                            positiveNegativeGap.negative.map((ratinggapdata, index) => (
                              
                              <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}} key={"negative_gap"+index}>
                                  <TableCell component='th' scope='row'>{ratinggapdata.domain}</TableCell>
                                  <TableCell>{ratinggapdata.question}</TableCell>                                       
                                  <TableCell align='right'>{ratinggapdata.other_rate}</TableCell>                                       
                                  <TableCell align='right'>{ratinggapdata.self_rate}</TableCell>                                       
                                  <TableCell align='right'>{ratinggapdata.gap}</TableCell>                                       
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>No Record Found</TableCell>
                            </TableRow>
                          )}                           
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>                   
            </div>
            {/* <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total</TableCell>
                                <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Self</TableCell>
                                <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                            </TableRow>                     
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
            </div> */}
          </div>                         
        </TabPanel>
        <TabPanel value="6">
          <h4 className="mb-3 mt-1 fs-20">Detailed Results</h4>
          <div className="row">
            <div className="col-md-12">
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-theme ft-light">WHAT THE REPORT MEANS TO YOU</TableCell>                                        
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>This information enables you to understand personal strengths as well as identify areas for potential improvement. The report also provides insights on your personal competency level in relation to others. The Description terms will allow you to interpret your results with additional detail.</TableCell>
                            </TableRow>                        
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr> 
                  <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-theme ft-light">DESCRIPTION OF TERMS</TableCell>                                        
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>To help you better understand your report, we have provided the following definitions for terms used throughout the report</TableCell>
                            </TableRow>                        
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                {detailedResults && detailedResults.length > 0 ? (
                  <>
                  <div className="col-md-12">
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        
                          {detailedResults.map((competency, index) => (
                            <>
                            {/* <div key={"detailed_results"+index}> */}
                              <TableHead>
                                <TableRow>
                                  <TableCell className="bg-theme ft-light" colSpan={3}>{competency.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="bg-secondary ft-light">Question</TableCell>
                                  <TableCell className="bg-secondary ft-light" align='right'>Result</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {competency.questions.map((ques, i) => (
                                  <TableRow key={i} sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                                    <TableCell>{ques.name}</TableCell>
                                    <TableCell align='right'><BarChart chartData={ques.surveyAnsers}/></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                              {/* </div> */}
                            </>
                          ))}
                        
                      </Table>
                    </TableContainer>                    
                  </div>
                  <hr></hr>  
                </>
                ) : ( '' )
                }                 
            </div>
            {/* <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total</TableCell>
                                <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Self</TableCell>
                                <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                            </TableRow>                     
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
            </div> */}
          </div>                         
        </TabPanel>
        {/* <TabPanel value="7">
          <h4 className="mb-3 mt-1">Interpreting your results</h4>
          <div className="row">
            <div className="col-md-7">
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-theme ft-light">WHAT THE REPORT MEANS TO YOU</TableCell>                                        
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>This information enables you to understand personal strengths as well as identify areas for potential improvement. The report also provides insights on your personal competency level in relation to others. The Description terms will allow you to interpret your results with additional detail.</TableCell>
                            </TableRow>                        
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr> 
                  <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-theme ft-light">DESCRIPTION OF TERMS</TableCell>                                        
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>To help you better understand your report, we have provided the following definitions for terms used throughout the report</TableCell>
                            </TableRow>                        
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>                   
            </div>
            <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>Total</TableCell>
                              <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                          </TableRow>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>Self</TableCell>
                              <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                          </TableRow>                     
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <hr></hr>
              </div>
            </div>                         
        </TabPanel> */}
    </TabContext>
  );
};

export default CardNavigationCenter;
