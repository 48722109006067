export const Footer = () => {
    return (
        <footer class="map-bg bg-light">
     <div class="container py-3 border-bottom z-index-20 position-relative">
         <div class="row pt-5 gy-4">
             <div class="col-lg-3 col-md-6"><img class="mb-3" src="./images/logo.png" alt="" width="150"/>
                 <ul class="list-unstyled text-muted">
                     <li><a class="reset-anchor fs-13 fw-500" href="mailto:info@peopleassay.com">info@peopleassay.com</a></li>
                     <li><a class="reset-anchor fs-13 fw-500" href="tel:+001234567890">+ 00 123 456 7890</a></li>
                 </ul>
                 <ul class="list-inline text-muted">
                     <li class="list-inline-item"><a class="reset-anchor" href="#!"><i class="fab fa-facebook-f"></i></a></li>
                     <li class="list-inline-item"><a class="reset-anchor" href="#!"><i class="fab fa-twitter"></i></a></li>
                     <li class="list-inline-item"><a class="reset-anchor" href="#!"><i class="fab fa-linkedin-in"></i></a></li>
                 </ul>
             </div>
             <div class="col-lg-3 col-md-6">
                 <h4>About us</h4>
                 <ul class="list-unstyled text-muted mb-0">
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">Support center</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">Customer support</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">About us</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">copyright</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">popular campaign</a></li>
                 </ul>
             </div>
             <div class="col-lg-3 col-md-6">
                 <h4>Our information</h4>
                 <ul class="list-unstyled text-muted mb-0">
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">Support center</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">Customer support</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">About us</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">copyright</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">popular campaign</a></li>
                 </ul>
             </div>
             <div class="col-lg-3 col-md-6 mb-4">
                 <h4>Policy</h4>
                 <ul class="list-unstyled text-muted mb-0">
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">Support center</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">Customer support</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">About us</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">copyright</a></li>
                     <li class="mb-1"><a class="fs-13 fw-500 reset-anchor" href="#!">popular campaign</a></li>
                 </ul>
             </div>
         </div>
     </div>
     <div class="container py-4 z-index-20 position-relative">
         <div class="row text-center">
             <div class="col-lg-6 p-lg-0 text-lg-start">
                 <p class="text-muted fs-13 fw-500 mb-0">© 2023 People Assay, All Rights Reserved.</p>
             </div>
             <div class="col-lg-6 p-lg-0 text-lg-end">
                 <p class="text-muted fs-13 fw-500 mb-0">designed by <a class="text-theme reset-anchor" href="https://actiknow.com/" target="_blank">Actiknow Consulting Pvt. Ltd.</a>
                 </p>
             </div>
         </div>
     </div>
 </footer>
    )
}

export default Footer;