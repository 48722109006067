// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import themeConfig from '../../configs/themeConfig'
import { Link, useNavigate } from 'react-router-dom'
import BlankLayout from '../Layout/BlankLayout'
import FooterIllustrationsV1 from './FooterIllustration'
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import Swal from 'sweetalert2';

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({    
    [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
    }
}))

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .required('Password is required')
});

export default function Login() {    
    const navigate = useNavigate();
    const initialValues = {
        email: '',
        password: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);            
        }
    });

    const handleSubmit = (values, setSubmitting) => {
        axios.post('/api/auth/login', values).then((res) =>{
            setSubmitting(false)
            if(res.data.success){
                console.log("login response===",res.data.data)
                localStorage.setItem('authUser', JSON.stringify(res.data.data));
                navigate('/dashboard');
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }

    return (        
        <BlankLayout>
            <Box className='content-center'>
                <Card sx={{ zIndex: 1 }}>
                    <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
                        <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Link to="/"><img src="./images/logo.png" alt="..." width="150"/></Link>
                        </Box>
                        <Box sx={{ mb: 6 }}>
                            <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                            Welcome to {themeConfig.templateName}! 👋🏻
                            </Typography>
                            <Typography variant='body2'>Please sign-in to your account and start the adventure</Typography>
                        </Box>
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <TextField autoFocus fullWidth 
                                ListboxProps={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                    marginBottom: 4
                                }}
                                id='email' 
                                label='Email'
                                value={formik.values.email} 
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <TextField fullWidth 
                                ListboxProps={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                    marginBottom: 4
                                }}
                                id='password' 
                                label='Password'
                                type='password'
                                value={formik.values.password} 
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <Box
                            sx={{ mb: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                            >
                                <FormControlLabel control={<Checkbox />} label='Remember Me' />
                                <Link className='fs-14' to='/forgot_password'>
                                    Forgot Password?
                                </Link>
                            </Box>
                            
                            <Button 
                                fullWidth
                                type='submit'
                                size='large'
                                variant='contained'
                                sx={{ marginBottom: 7 }}
                                disabled={formik.isSubmitting}
                            >
                                Login
                            </Button>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                                <Typography variant='body2' sx={{ marginRight: 2 }}>
                                    New on our platform?
                                </Typography>
                                <Typography variant='body2'>
                                    <Link  to='/signup'>
                                        Create an account
                                    </Link>
                                </Typography>
                            </Box>
                        </form>
                    </CardContent>
                </Card>
                <FooterIllustrationsV1 />
            </Box>
        </BlankLayout>
    )
}