import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2'
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchema = yup.object({
    // surveyId: yup.string().required('Survey is required'),
    name: yup.string('Enter your name').required('Name is required')
});

export const Add = ({type, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {  
    const [competencies, setCompetencies] = useState([]);
    const [selectedCompetencies, setSelectedCompetencies] = useState([]);
    const initialValues = {
        id: editId,
        name: '',
        competencies: [],
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });
    
    useEffect(() => {
        getCompetencies()
        if(editId > 0){
            axios.get(`api/singleSurvey?id=${editId}`).then(res => {
                console.log("res===",res);
                if(res.data.success){
                    formik.setFieldValue('id', res.data.data.id)
                    formik.setFieldValue('name', res.data.data.name)
                    const formattedCompetenciesArray =  res.data.data.survey_competencies.map(item => `${item.competency.id}-${item.competency.name}`);
                    formik.setFieldValue('competencies', formattedCompetenciesArray)
                }
            })
        }
        else{
            
        }
        
    },[editId]);

    useEffect(() => {
        formik.setFieldValue('competencies', selectedCompetencies)  
    }, [selectedCompetencies])

    const getCompetencies = async () => {
        let apiUrl = "/api/competencies?status=1";

        axios.get(apiUrl).then(function (response) {
            let result = response.data;
            if (result.success) {
                setCompetencies(result.data);
            } else {
                // Handle error or set isError state if needed.
            }
        });
    };
    
    const handleSubmit = (values) =>{
        if(editId > 0){
            axios.put(`api/surveys/update`, values).then(res => {
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();                        
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`api/surveys/create`, values).then(res => {
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                        
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }        
    }

    const handleCompetencyChange = (e) => {
        setSelectedCompetencies(e.target.value)
    }
    const renderCompetencyValue = (selected) => {
        let c_names = ''
        for(let i = 0; i < selected.length; i++){
            let temp = selected[i].split('-')
            c_names += temp[1]
            if(i !== selected.length - 1){
                c_names += ', '
            }
        }
        return c_names
    }
    
    
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9999}}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">{type} Survey</h5>
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth size='small'
                            id='name'
                            label='Survey Name'  
                            placeholder='Survey Name'
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                              }}
                              sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                              }}
                            value={formik.values.name} 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <FormControl
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                              }}
                              sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                                marginTop: 5, width: '100%'
                              }} >
                            <InputLabel size='small' id="competencies-label">Competencies</InputLabel>
                            <Select
                                size='small'
                                labelId="competencies-label"
                                id="competencies"
                                multiple
                                value={formik.values.competencies}
                                input={<OutlinedInput label="Competencies" />}
                                onChange={handleCompetencyChange}
                                renderValue={(selected) => renderCompetencyValue(selected) }
                                MenuProps={MenuProps}
                            >
                            {competencies.length > 0 ? competencies.map((competency) => (
                                <MenuItem className='MenuItem' key={competency.id} value={competency.id+'-'+competency.name}>
                                    <Checkbox checked={formik.values.competencies.indexOf(competency.id+'-'+competency.name) > -1}/>
                                    <ListItemText primary={competency.name} id={"comp"+competency.id} />
                                </MenuItem>
                            )): ''}
                            </Select>
                        </FormControl>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='reset' sx={{ marginRight: 3.5 }} variant='outlined' size='small' onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant='contained' size='small'>
                        {type === 'Add' ? 'Add' : 'Update'}
                    </Button>              
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};