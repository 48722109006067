import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IconButton, Tooltip } from '@mui/material';
import Delete from '@mui/icons-material/Delete';

const validationSchema = Yup.object().shape({
    subjectId: Yup.string().required('Please select a subject'),
    surveyId: Yup.string().required('Please select a survey type'),
    surveyRatingSettingId: Yup.string().required('Please select a setting'),
    raters: Yup.array().min(1, 'Please add at least one rater'),
    raterName: Yup.string().ensure().when('raterEmail', {
        is: (val) => val !== '',
        then: Yup.string().required('Rater Name is required')
    }),
    raterEmail: Yup.string().ensure().when('raterName', {
        is: (val) => val !== '',
        then: Yup.string().email('Please enter valid email').required('Rater Email is required')
    }),
    raterGroup: Yup.string().ensure().when('raterName', {
        is: (val) => val !== '',
        then: Yup.string().required('Rater Group is required')
    }),
},[['raterName', 'raterEmail']]);

export default function SendAssessment() {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [subjects, setSubjects] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [surveySettings, setSurveySettings] = useState([]);
    const [raterGroups, setRaterGroups] = useState([]);

    const initialValues = {
        subjectId: '',
        surveyId: '',
        surveyRatingSettingId: '',
        raters: [],
        raterName: '',
        raterEmail: '',
        raterGroup: '',
        raterGroupId: '',
        raterGroupName: ''
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting); 
        },
      });

    useEffect(() => {
        if(user.role !== 'Admin'){
            navigate('/dashboard')
        }
        getSubjects()
        getSurveys()
        getSurveySettingList()
        getRaterGroups()
    }, [])

    const getSubjects = async() => {
        await axios.get(`/api/subjects?forAssessment=1`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setSubjects(result.data)
            }
        })
    }

    const getSurveys = async () => {
        await axios.get("/api/surveys").then(function (response) {
            let result = response.data;
            if (result.success) {
                setSurveys(result.data);
            } else {
                // setIsError(true);
            }
        });
    };

    const getSurveySettingList = async () => {
        await axios.get(`/api/surveyratingsettings?createdBy=${user.id}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                let idFound = 0;
                for (const item of result.data) {
                    if (item.createdBy !== user.id) {
                      idFound = item.id;
                      break;
                    }
                }
                // formik.values.surveyRatingSettingId = idFound
                setSurveySettings(result.data);
            } else {
                // setIsError(true);
            }
        });
    };

    const getRaterGroups = async() => {
        await axios.get(`/api/rater_groups`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setRaterGroups(result.data)
            }
        })
    }

    const addRater = () => {        
        if(formik.values.raterName != '' && formik.values.raterEmail != '' && formik.values.raterGroupId != ''){
            formik.setFieldValue('raters', [...formik.values.raters, {
                name: formik.values.raterName,
                email: formik.values.raterEmail,
                raterGroupId: formik.values.raterGroupId,
                raterGroupName: formik.values.raterGroupName,
            }])

            formik.setFieldValue('raterName', '')
            formik.setFieldValue('raterEmail', '')
            formik.setFieldValue('raterGroup', '')
            formik.setFieldValue('raterGroupId', '')
            formik.setFieldValue('raterGroupName', '')
        }
        else{
            // alert('asd')
        }
    }

    const removeRater = (index) => {
        const updatedRaters = [...formik.values.raters];
        updatedRaters.splice(index, 1);
        // setRaters(updatedRaters)
        formik.setFieldValue('raters', updatedRaters);
    };

    const handleRaterGroupChange = (e) => {
        console.log("handleRaterGroupChange",e)
        let nameValue = e.target.value.split('.')
        formik.setFieldValue('raterGroup', e.target.value)
        formik.setFieldValue('raterGroupId', nameValue[0])
        formik.setFieldValue('raterGroupName', nameValue[1])
    }

    const handleSubmit = (values, setSubmitting) => {
        if (formik.isValid) {
            // Your form submission logic goes here
            axios.post('/api/sendAssessment', values).then(res => {   
                setSubmitting(false)
                if(res.data.success){
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        navigate('/assessments');
                    })
                }
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                Swal.fire(error.response.data.message, '', 'error')
            });

        }
    }

    return (
        <UserLayout>
            <div className="row">
                <div className='col-md-4 col-6 mb-3'>
                    <h1>Send Assessment</h1>
                </div>
                <div className='col-md-8 col-12 text-md-end mb-3'>
                    {/* <Button size='small' variant='contained'>Send Assessment</Button> */}
                </div>
            </div>
            <form autoComplete="off" onSubmit={formik.handleSubmit} className="text-left mb-3">
                <Grid container spacing={6}>            
                    <Grid item xs={12} sm={4}>
                        <TextField
                            listboxprops={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            fullWidth
                            select
                            label="Select Subject"
                            name="subjectId"
                            size='small'
                            value={formik.values.subjectId}
                            onChange={formik.handleChange}
                            // disabled={currentSession.user_sessions && currentSession.user_sessions.length>0}
                            error={formik.touched.subjectId && Boolean(formik.errors.subjectId)}
                            helperText={formik.touched.subjectId && formik.errors.subjectId}                         
                        >
                            <MenuItem value="" disabled>
                                Please Select
                            </MenuItem>
                            {subjects.length > 0 ? (
                                subjects.map((subject) => (
                                    <MenuItem key={subject.id} value={subject.id}>
                                        {subject.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    No surveys available
                                </MenuItem>
                            )}
                        </TextField>
                        {/* {formik.touched.surveyId && Boolean(formik.errors.surveyId) && (
                            <div className="text-danger">{formik.errors.surveyId}</div>
                        )} */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            listboxprops={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            fullWidth
                            select
                            label="Select Survey Type"
                            name="surveyId"
                            size='small'
                            value={formik.values.surveyId}
                            onChange={formik.handleChange}
                            // disabled={currentSession.user_sessions && currentSession.user_sessions.length>0} 
                            error={formik.touched.surveyId && Boolean(formik.errors.surveyId)}
                            helperText={formik.touched.surveyId && formik.errors.surveyId}                               
                        >
                            <MenuItem value="" disabled>
                                Please Select
                            </MenuItem>
                            {surveys.length > 0 ? (
                                surveys.map((survey) => (
                                    <MenuItem key={survey.id} value={survey.id}>
                                        {survey.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    No surveys available
                                </MenuItem>
                            )}
                        </TextField>
                        {/* {formik.touched.surveyId && Boolean(formik.errors.surveyId) && (
                            <div className="text-danger">{formik.errors.surveyId}</div>
                        )} */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                        listboxprops={{
                            sx: { fontSize: 13,
                                fontFamily: 'Poppins' },
                            }}
                            sx={{
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: 13,
                                fontFamily: 'Poppins', // Add your desired font family
                                fontWeight: '500', // Add your desired font weight
                            },
                            }}
                        variant={"outlined"}
                        style={{width: "100%"}}
                        fullWidth
                        select 
                        label='Select Survey Setting' 
                        name="surveyRatingSettingId" 
                        size='small'
                        value={formik.values.surveyRatingSettingId}
                        onChange={formik.handleChange}
                        // disabled={currentSession.user_sessions && currentSession.user_sessions.length>0} 
                        error={formik.touched.surveyRatingSettingId && Boolean(formik.errors.surveyRatingSettingId)}
                        helperText={formik.touched.surveyRatingSettingId && formik.errors.surveyRatingSettingId}
                        >
                            <MenuItem value="" disabled> Please Select </MenuItem>
                            {surveySettings.length > 0 ? (
                                surveySettings.map((setting) => (
                                    <MenuItem key={setting.id} value={setting.id}>{setting.name}</MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    No setting available
                                </MenuItem>
                            )}
                        </TextField>
                    </Grid> 
                </Grid>
                <div className="row mt-4">
                    <div className='col-md-4 col-6 mb-2'>
                        <h1>Raters</h1>
                    </div>
                    <div className='col-md-8 col-12 text-md-end mb-2'>
                        <Button size='small' variant='contained'>Upload Excel</Button>
                    </div>
                </div>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            size='small'
                            label='Rater Name'
                            id='raterName'  // Adjusted the id attribute
                            value={formik.values.raterName}
                            onChange={formik.handleChange}
                            error={formik.touched.raterName && formik.touched.raterName && Boolean(formik.errors.raterName)}
                            helperText={formik.touched.raterName && formik.touched.raterName && formik.errors.raterName}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            size='small'
                            label='Rater Email'
                            id='raterEmail'  // Adjusted the id attribute
                            value={formik.values.raterEmail}
                            onChange={formik.handleChange}
                            error={formik.touched.raterEmail && formik.touched.raterEmail && Boolean(formik.errors.raterEmail)}
                            helperText={formik.touched.raterEmail && formik.touched.raterEmail && formik.errors.raterEmail}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }} 
                            select
                            size='small'
                            label='Rater Group'
                            id='raterGroup'
                            value={formik.values.raterGroup}
                            onChange={(e) => handleRaterGroupChange(e)}                                                
                            error={formik.touched.raterGroup && formik.touched.raterGroup && Boolean(formik.errors.raterGroup)}
                            helperText={formik.touched.raterGroup && formik.touched.raterGroup && formik.errors.raterGroup}
                        >
                            <MenuItem value={""}>Please Select</MenuItem>
                            {
                                raterGroups.length > 0 ? 
                                raterGroups.map(raterGroup => (
                                    <MenuItem key={raterGroup.name} value={raterGroup.id+'.'+raterGroup.name}>{raterGroup.name}</MenuItem>
                                ))
                                : ''
                            }
                        </TextField>                                            
                    </Grid>                    
                    <Grid item xs={12} md={1}>
                        <Button onClick={addRater} className="text-end" size='small' type="button" variant="contained">
                            Add
                        </Button>
                    </Grid>
                </Grid>
                {formik.touched.raters && formik.errors.raters && (
                    <div className="text-danger fs-13">{formik.errors.raters}</div>
                )}    
                <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                        <div className="table-responsive mt-4">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">S. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Rater Group</th>
                                        <th scope="col">Action</th>                                                
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formik.values.raters.length > 0 ? 
                                        formik.values.raters.map((rater, i) => {
                                            return(
                                                <tr>
                                                    <th scope="row">{i+1}</th>
                                                    <td>{rater.name}</td>
                                                    <td>{rater.email}</td>
                                                    <td>{rater.raterGroupName}</td>
                                                    <td>                                                        
                                                        <Tooltip onClick={removeRater} title="Delete" size='small' className='ss_delete'>
                                                            <IconButton>
                                                                <Delete />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>                                               
                                                </tr>
                                            )
                                        })
                                        : ''
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Grid>
                </Grid>
                <Button className="text-end" style={{marginTop:25, marginRight:15}} size='small' type="submit" variant="contained" disabled={formik.isSubmitting}>
                    Send Assessment
                </Button>
            </form>
        </UserLayout>
    )
}