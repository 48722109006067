// ** Icon imports
import HomeOutline from 'mdi-material-ui/HomeOutline'
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import ListIcon from '@mui/icons-material/List';
import SentimentSatisfied from '@mui/icons-material/SentimentSatisfied';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PollIcon from '@mui/icons-material/Poll';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const Navigation = () => {  
  const authUser = localStorage.getItem('authUser');
  const user = JSON.parse(authUser);
  // console.log(user.id);
  const sidebarItems = [];

  sidebarItems.push({ title: 'Dashboard', icon: HomeOutline, path: '/dashboard' });
  if(user.role === 'SuperAdmin'){
    sidebarItems.push({ title: 'Companies', icon: BusinessIcon, path: '/companies' });
    sidebarItems.push({ title: 'Subscription Plans', icon: CardMembershipIcon, path: '/plans' });
    sidebarItems.push({ sectionTitle: 'Survey' });
    sidebarItems.push({ title: 'Survey', icon: ListIcon, path: '/surveys' });
    sidebarItems.push({ title: 'Competencies', icon: AlbumOutlinedIcon, path: '/competencies' });
    // sidebarItems.push({ title: 'Questions', icon: HelpOutlineIcon, path: '/questions' });
    sidebarItems.push({ sectionTitle: 'Account' });
    sidebarItems.push({ title: 'Profile', icon: Person2OutlinedIcon, path: '/profile' });
    
    // sidebarItems.push({ title: 'Competencies - 2', icon: PollIcon, path: '/competencies-new' });

  }
  if(user.role === 'Admin'){
    sidebarItems.push({ title: 'Subjects', icon: PeopleAltIcon, path: '/subjects' });
    sidebarItems.push({ title: 'Rater Groups', icon: PeopleAltIcon, path: '/rater_groups' });
    sidebarItems.push({ title: 'Assessments', icon: NewReleasesIcon, path: '/assessments' });
    // sidebarItems.push({ title: 'Session Manager', icon: NewReleasesIcon, path: '/sessions' });
    sidebarItems.push({ title: 'Generated Reports', icon: PollIcon, path: '/reports' });
    sidebarItems.push({ sectionTitle: 'Survey' });
    sidebarItems.push({ title: 'Survey List', icon: ListIcon, path: '/surveys' });
    sidebarItems.push({ title: 'Competencies', icon: AlbumOutlinedIcon, path: '/competencies' });
    // sidebarItems.push({ title: 'Questions', icon: HelpOutlineIcon, path: '/questions' });
    sidebarItems.push({ sectionTitle: 'Settings' });
    sidebarItems.push({ title: 'Rating Defaults', icon: SentimentSatisfied, path: '/settings_survey' });
    // sidebarItems.push({ title: 'Report', icon: ReportGmailerrorredIcon, path: '/settings_report' });
    sidebarItems.push({ sectionTitle: 'Account' });
    sidebarItems.push({ title: 'Profile', icon: Person2OutlinedIcon, path: '/profile' });
  }
  if(user.role === 'User'){
    sidebarItems.push({ title: 'Self Evaluation', icon: PollIcon, path: '/self_evaluation' });    
  }   

  return sidebarItems;
}

export default Navigation
