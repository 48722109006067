import React, { useEffect, useState }  from 'react';
import UserLayout from '../Layout/UserLayout'
import Add from './Add'
import axios from 'axios';
// import Swal from 'sweetalert2';
import {Grid, Card, Accordion, AccordionDetails, AccordionSummary, Typography, Button, IconButton, Tooltip} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Competency from '../Common/Competency';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

export default function List() {
    const authUser = localStorage.getItem('authUser');
    const role = JSON.parse(authUser).role;
    const authUserId = JSON.parse(authUser).id;
    const [show, setShow] = useState(false);
    const [popupType, setPopupType] = useState('Add');
    const [editId, setEditId] = useState(0);
    const [surveys, setSurveys] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [users, setUsers] = useState([]);
    const [surveyExpanded, setSurveyExpanded] = useState(false);

    useEffect(() => {
        getList();
    }, [selectedUser]);
    useEffect(() => {
        getUsersList();
    }, []);

    const getList = async() => {
        let apiUrl = "/api/surveys";

        if(role === 'SuperAdmin'){
            apiUrl = `/api/surveys?userId=${selectedUser}`;
        }
        await axios.get(apiUrl)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setSurveys(result.data)
            }
            else {
                // setIsError(true);
            }
        })
    }

    const getUsersList = async () => {
        await axios.get(`/api/users?role=Admin&parentId=2`).then(function (response) {
            let result = response.data;
            if (result.success) {
                setUsers(result.data);
                // console.log(result.data);
            } else {
                // setIsError(true);
            }
        });
    };
    
    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }

    const handleClose = () => setShow(false);

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }
    // const handleSelectChange = (e) => {
    //     const selectedUserId = e.target.value;
    //     // const selectedUser1 = users.find(user => user.id === selectedUserId);
    //     setSelectedUser(selectedUserId);
    //     console.log("selectedUser", selectedUserId);
    // };

    const handleSurveyExpand = (panel) => (event, isExpanded) => {
        setSurveyExpanded(isExpanded ? panel : false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/surveys/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success').then(result => { 
                            getList();
                        })
                    }
                    else{
                        Swal.fire(res.data.message, '', 'info')
                    } 
                    
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
              } else if (result.isDenied) {
                Swal.fire('Cancelled!', '', 'info')
              }
          })
    }

    const columns = [];
    columns.push({ id: 'name', label: 'Name', minWidth: 150 });
    if(role === 'SuperAdmin'){
        columns.push({ id: 'user', label: 'Company Name' });
    }    
    columns.push({ id: 'status', label: 'Status', minWidth: 100 });
    columns.push({ id: 'action', label: 'Action'});

    return (
        <UserLayout>
            <div className="row">
                <div className='col-md-8 col-6 mb-3'>
                    <h1 className=''>Survey</h1>
                </div>
                {/* <div className='col-md-4'>
                {role === "SuperAdmin" && (
                        <TextField fullWidth select
                            label='Select Company'
                            id='ComapanyId'
                            value={selectedUser ? selectedUser.id : ""}
                            onChange={handleSelectChange}
                            >
                            <MenuItem selected hidden>Please Select</MenuItem>
                            <MenuItem value="2">Super Admin</MenuItem>
                            {
                                users.length > 0 ? 
                                users.map(user => (
                                    <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                                ))
                                : ''
                            }
                        </TextField>
                         )}
                </div> */}
                <div className='col-md-4 col-6 text-end mb-3'>
                    <Button size='small' variant='contained' onClick={()=>handleAdd()}>
                        Add Survey
                    </Button>
                </div>
            </div>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <div>
                        {surveys.map((survey) => (
                            <Accordion
                                key={survey.id}
                                expanded={surveyExpanded === 'spanel' + survey.id}
                                onChange={handleSurveyExpand('spanel' + survey.id)}
                            >
                                <AccordionSummary sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}} expandIcon={<ExpandMoreIcon />} aria-controls={`spanel${survey.id}-content`} id={`spanel${survey.id}-header`}>
                                    <Grid sx={{width: '75%'}}>
                                        <Typography className='fw-500' sx={{ width: '90%', flexShrink: 0 }}>{survey.name}  </Typography>
                                    </Grid>
                                    <Grid sx={{width: '25%', textAlign: 'end', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                        <Link className='text-end' to={'/survey/'+survey.id} target="_Blank">
                                            <Tooltip size='small' className='ss_view' sx={{ marginRight: 2 }}> 
                                                <IconButton size='small'><VisibilityIcon /></IconButton> 
                                            </Tooltip>
                                        </Link> 
                                        
                                        { authUserId === survey.userId ? 
                                            <>                          
                                                <Tooltip onClick={() => handleEdit(survey.id)} size='small' className='ss_edit' sx={{ marginRight: 2 }}> 
                                                    <IconButton size='small'><EditIcon /></IconButton> 
                                                </Tooltip>
                                                <Tooltip onClick={() => handleDelete(survey.id)} size='small' className='ss_delete'> 
                                                    <IconButton size='small'><DeleteIcon /></IconButton> 
                                                </Tooltip>
                                            </>
                                        :''}
                                    </Grid>
                                    
                                    
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Competency competencies={survey}/>                                        
                                </AccordionDetails>
                            </Accordion>
                        ))}
                        </div>
                    </Card>
                </Grid>
            </Grid>
            <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId}/>
        </UserLayout>
    )
}