import React from 'react';
import {Chart, 
  ArcElement, 
  // Tooltip, Legend, Title
} from 'chart.js';
import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement, 
  // Tooltip,// Legend, Title
  );
const MaxValue = 5;
const DoughnutChart = ({chartData}) => {
  const data = {
    labels: ['Self', 'Others', 'Average'],
    datasets: [

      {
        label:"Self",
        data: [chartData.self, (MaxValue-chartData.self).toFixed(1)],
        backgroundColor: ['#eb5151','#eee'],
        borderColor: "#D1D6DC",
        borderWidth: 1,
        radius: '100%',
        // circumference:180,
        // rotation:270,
        maintainAspectRatio: true,
      },
      {
        label:"Others",
        data: [chartData.other,(MaxValue-chartData.other).toFixed(1)],
        backgroundColor: ['#2e689a','#eee'],
        borderColor: "#D1D6DC",
        borderWidth: 1,
        radius: '100%%',
        // circumference:180,
        // rotation:270,
        maintainAspectRatio: true,
      },
      {
        label:"Average",
        data: [chartData.average,(MaxValue-chartData.average).toFixed(1)],
        backgroundColor: ['#1d232d','#eee'],
        borderColor: "#D1D6DC",
        borderWidth: 1,
        radius: '100%',
        // circumference:180,
        // rotation:270,
        maintainAspectRatio: true,
      }
    ]
  };

  const options = {
      plugins: {
        datalabels: {
          display: true,
          color:'white',
          // align: 'bottom',
          // backgroundColor: '#ccc',
          // borderRadius: 3,
          // font: {
          //   size: 18,
          // },
        },
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        },
        title: {
          display: true,
          font: {
            size: 14,
          },
          text: chartData.domain,
          position:'bottom',
        }
      },
      rotation: -90,
      circumference: 180,
      // cutout: "40%",
      // maintainAspectRatio: true,
      responsive: true
  }

  return (
    <Doughnut data={data} options={options} />
  );
}

export default DoughnutChart;

