import React from 'react';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Testimonial = () => {
    //Owl Carousel Settings
    const state = {
        // margin: 30,
        responsiveClass: true,
        nav: true,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,
            }
        },
    };
    return (
     <section className="bg-theme map-bg">
        <div className="container text-center py-5">
            <div className="row mb-4">
                <div className="col-lg-9 mx-auto text-white">
                    <p className="h6 text-uppercase">What Our Customers Say</p>
                    <h2 className="mb-3">Real Stories, Real Results</h2>
                    <p className="">Discover how organizations like yours are leveraging PeopleAssay to drive growth, boost performance, and create a culture of feedback. Read our customer success stories and see the impact for yourself.</p>
                </div>
            </div>
            <div className="row gy-3 pb-2">
                <div className='col-md-12'>
                    <OwlCarousel className='ss_carousel' dots={false} responsive={state.responsive} items={3} autoplay ={true} touchDrag={true} lazyLoad={true}>
                        <div className="item">
                            <div className="card border-0">
                                <div className="card-body p-lg-5">
                                    <div className='text-center'>
                                        <img src="./images/bi_quote.svg" className="img_size" alt="..."/>
                                    </div>
                                    <p className="fs-13 mb-2 fw-500">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    <div className='text-center mb-2'>
                                        <img src="./images/avatars/2.png" className="rounded-circle img_avtar" alt="..."/>
                                    </div>
                                    <h6 className="mb-0">Lorem ipsum</h6>
                                    <span className="text-muted fs-12 fw-500">Lorem ipsum dolor sit amet</span>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card border-0">
                                <div className="card-body p-lg-5">
                                    <img src="./images/bi_quote.svg" className="img_size" alt="..."/>
                                    <p className="fs-13 mb-2 fw-500">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    <img src="./images/avatars/3.png" className="rounded-circle img_avtar mb-2" alt="..."/>
                                    <h6 className="mb-0">Lorem ipsum</h6>
                                    <span className="text-muted fs-12 fw-500">Lorem ipsum dolor sit amet</span>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card border-0">
                                <div className="card-body p-lg-5">
                                    <img src="./images/bi_quote.svg" className="img_size" alt="..."/>
                                    <p className="fs-13 mb-2 fw-500">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    <img src="./images/avatars/4.png" className="rounded-circle img_avtar mb-2" alt="..."/>
                                    <h6 className="mb-0">Lorem ipsum</h6>
                                    <span className="text-muted fs-12 fw-500">Lorem ipsum dolor sit amet</span>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card border-0">
                                <div className="card-body p-lg-5">
                                    <img src="./images/bi_quote.svg" className="img_size" alt="..."/>
                                    <p className="fs-13 mb-2 fw-500">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    <img src="./images/avatars/1.png" className="rounded-circle img_avtar mb-2" alt="..."/>
                                    <h6 className="mb-0">Lorem ipsum</h6>
                                    <span className="text-muted fs-12 fw-500">Lorem ipsum dolor sit amet</span>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>                
            </div>
        </div>
    </section>
    )
}

export default Testimonial;