import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import TableStickyHeader from '../Common/TableStickyHeader'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import axios from 'axios'
import Swal from 'sweetalert2'
import Add from './Add'

export default function List() {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [popupType, setPopupType] = useState('Add');
    const [editId, setEditId] = useState(0);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        if(user.role !== 'Admin'){
            navigate('/dashboard')
        }
        getList();
    }, [])

    const getList = async() => {
        await axios.get(`/api/rater_groups`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setUsers(result.data)
            }
        })
    }

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }
    
    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/rater_group?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')
                        getList();
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const columns = [
        { id: 'name', label: 'Name', minWidth: 120 },
        { id: 'action', label: 'Action'}
    ]

    return (
        <UserLayout>
            <div className="row">
                <div className='col-md-4 col-6 mb-3'>
                    <h1>Rater Groups</h1>
                </div>
                <div className='col-md-8 col-12 text-md-end mb-3'>
                    <Button size='small' variant='contained' onClick={handleAdd}>Add Rater Group</Button>
                </div>
            </div>
            <Grid container spacing={6}>            
                <Grid item xs={12}>
                    <Card>
                        <TableStickyHeader columns={columns} rows={users} setEditId={setEditId} setPopupType={setPopupType} setShow={setShow} handleEdit={handleEdit} handleDelete={handleDelete} />
                    </Card>
                </Grid>           
            </Grid>

            <Add type={popupType} show={show} setShow={setShow} getList={getList} editId={editId} setEditId={setEditId}/>
        </UserLayout>
    )
}