export default function Banner() {
    return (
        <section className="hero hero-home with-pattern d-flex align-items-center py-5">
        <div className="container mt-5 pt-4">
            <div className="row align-items-center">
                <div className="col-md-6 mb-5 mb-md-0">
                    <h1 className="mb-3 fw-bolder banner_head">Unlock Growth, <span className="text-theme">One Feedback </span>at a Time</h1>
                    <p className="text-muted fw-semibold">Empower Your Team with Actionable Insights</p>
                    <span className="text-muted fs-14">Transform your organization with PeopleAssay, the all-in-one 360-degree feedback platform designed to drive growth, foster collaboration, and unleash the full potential of your team.</span>
                    <a className="video-btn d-flex align-items-center" href="#!" data-video-id="B6uuIHpFkuo"><span className="video-btn-icon"><i className="fas fa-play"></i></span>
                        {/* <div className="ms-3"><span className="text-muted d-block line-height-sm">Watch</span><strong className="text-uppercase text-dark">Intro Video</strong></div> */}
                    </a>
                </div>
                <div className="col-md-6 ">
                    <div className="img-gradient img-gradient-right"><img className="img-fluid shadow rounded" src="./images/hero-img.jpg" alt=""/></div>
                </div>
            </div>
        </div>
    </section>
    )
}